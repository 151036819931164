import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const FluidImg = ({fileName, alt=""}) => {

  const data = useStaticQuery(
    graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  const file = data.images.edges.find(n => {
    return n.node.relativePath.includes(fileName)
  });

  if (!file) return;

  return (
    <Img fluid={file.node.childImageSharp.fluid} alt={alt} />
  )
}
