import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ArticleCard from "./ArticleCard";

export function PageCard ({id}) {

  const { allMdx: {edges} } = useStaticQuery(
    graphql`
      query {
        allMdx {
          edges {
            node {
              frontmatter {
                id
                title
                category
                description
                createdDate
                updatedDate
                tags
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 1280) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const pages = edges.filter(edge => edge.node.frontmatter.id === id);

  if (pages.length === 0) {
    return(<></>);
  }

  const page = pages[0].node.frontmatter;

  return (
    <ArticleCard
      featuredImage={page.featuredImage}
      title={page.title}
      category={page.category}
      createdDate={page.createdDate}
      updatedDate={page.updatedDate}
      excerpt={page.excerpt}
      url={`/${page.id}`}
      description={page.description}
    />
  );
}
