import React from "react"
import styled from "styled-components"
import Img from "gatsby-image";
import theme, {breakpoints} from "../style/theme"

const ImgWrapper = styled.p`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  //&:before {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  display: ${props => props.category ? 'block' : 'none'};
  //  padding: 0.25rem 0.5rem;
  //  content: '${props => props.category}';
  //  color: ${theme.palette.text.primary};
  //  font-size: 0.85rem;
  //  background-color: ${props => props.color};
  //  opacity: .9;
  //  z-index: 5;
  //  @media screen and (max-width: ${breakpoints.sm}px) {
  //    display: ${props => props.sp && props.category ? 'block' : 'none'};
  //  }
  //}
  //&:after {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  display: ${props => props.category ? 'block' : 'none'};
  //  padding: 0.25rem 0.5rem;
  //  content: '${props => props.category}';
  //  color: ${theme.palette.white};
  //  font-size: 0.85rem;
  //  z-index: 10;
  //  @media screen and (max-width: ${breakpoints.sm}px) {
  //    display: ${props => props.sp && props.category ? 'block' : 'none'};
  //  }
  //}
`

export const ArticleImage = ({category, color, featuredImage, sp=true}) => {
  return (
    <ImgWrapper category={category} color={color} sp={sp}>
      <Img
        fluid={featuredImage.childImageSharp.fluid}
      />
    </ImgWrapper>
  )
}