export const AmazonLinks = [
  // `駆け出しエンジニア`
  {
    title: "リーダブルコード ―より良いコードを書くためのシンプルで実践的なテクニック",
    date: "",
    author: "",
    categories: ["駆け出しエンジニア"],
    url: "https://amzn.to/36oMmPk",
    img: `<a href="https://www.amazon.co.jp/%E3%83%AA%E3%83%BC%E3%83%80%E3%83%96%E3%83%AB%E3%82%B3%E3%83%BC%E3%83%89-%E2%80%95%E3%82%88%E3%82%8A%E8%89%AF%E3%81%84%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E6%9B%B8%E3%81%8F%E3%81%9F%E3%82%81%E3%81%AE%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB%E3%81%A7%E5%AE%9F%E8%B7%B5%E7%9A%84%E3%81%AA%E3%83%86%E3%82%AF%E3%83%8B%E3%83%83%E3%82%AF-Theory-practice-Boswell/dp/4873115655?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%83%AA%E3%83%BC%E3%83%80%E3%83%96%E3%83%AB%E3%82%B3%E3%83%BC%E3%83%89&qid=1623671350&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=760843245eec832569d642faad704196&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4873115655&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4873115655" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "プログラムはなぜ動くのか 第3版 知っておきたいプログラミングの基礎知識",
    date: "",
    author: "",
    categories: ["駆け出しエンジニア"],
    url: `https://amzn.to/3zsdPNs`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0%E3%81%AF%E3%81%AA%E3%81%9C%E5%8B%95%E3%81%8F%E3%81%AE%E3%81%8B-%E7%AC%AC3%E7%89%88-%E7%9F%A5%E3%81%A3%E3%81%A6%E3%81%8A%E3%81%8D%E3%81%9F%E3%81%84%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%E3%81%AE%E5%9F%BA%E7%A4%8E%E7%9F%A5%E8%AD%98-%E7%9F%A2%E6%B2%A2-%E4%B9%85%E9%9B%84/dp/4296000195?dchild=1&qid=1623671419&s=books&sr=1-13&linkCode=li2&tag=htech-22&linkId=5c0322cacac7b02c8e4932d8dc530fdb&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4296000195&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4296000195" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "Webを支える技術 -HTTP、URI、HTML、そしてREST (WEB+DB PRESS plus)",
    date: "",
    author: "",
    categories: ["駆け出しエンジニア"],
    url: `https://amzn.to/3pOQ3qG`,
    img: `<a href="https://www.amazon.co.jp/Web%E3%82%92%E6%94%AF%E3%81%88%E3%82%8B%E6%8A%80%E8%A1%93-HTTP%E3%80%81URI%E3%80%81HTML%E3%80%81%E3%81%9D%E3%81%97%E3%81%A6REST-WEB-PRESS-plus/dp/4774142042?_encoding=UTF8&qid=1623671523&sr=1-1&linkCode=li2&tag=htech-22&linkId=94966269928b16ebc9ca51cf62750f5e&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4774142042&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4774142042" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  // `SES`
  {
    title: "SEを極める50の鉄則 新装版",
    date: "",
    author: "",
    categories: ["SES"],
    url: `https://amzn.to/3xlj008`,
    img: `<a href="https://www.amazon.co.jp/SE%E3%82%92%E6%A5%B5%E3%82%81%E3%82%8B50%E3%81%AE%E9%89%84%E5%89%87-%E6%96%B0%E8%A3%85%E7%89%88-%E9%A6%AC%E5%A0%B4-%E5%8F%B2%E9%83%8E/dp/4296102486?_encoding=UTF8&qid=1623673163&sr=1-3&linkCode=li2&tag=htech-22&linkId=cc0c539c5764ee63ea218694ae2506d0&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4296102486&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4296102486" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "ソフトウェア・ファースト あらゆるビジネスを一変させる最強戦略",
    date: "",
    author: "",
    categories: ["SES"],
    url: `https://amzn.to/3iL34Ah`,
    img: `<a href="https://www.amazon.co.jp/%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E3%83%BB%E3%83%95%E3%82%A1%E3%83%BC%E3%82%B9%E3%83%88-%E3%81%82%E3%82%89%E3%82%86%E3%82%8B%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%82%92%E4%B8%80%E5%A4%89%E3%81%95%E3%81%9B%E3%82%8B%E6%9C%80%E5%BC%B7%E6%88%A6%E7%95%A5-%E5%8F%8A%E5%B7%9D-%E5%8D%93%E4%B9%9F/dp/4822289915?_encoding=UTF8&qid=1623673163&sr=1-15&linkCode=li2&tag=htech-22&linkId=9e57af85a2f68a0ef52c28e71e5e69bb&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4822289915&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4822289915" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "プロダクトマネジメントのすべて 事業戦略・IT開発・UXデザイン・マーケティングからチーム・組織運営まで",
    date: "",
    author: "",
    categories: ["SES"],
    url: `https://amzn.to/3gm08Zd`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%97%E3%83%AD%E3%83%80%E3%82%AF%E3%83%88%E3%83%9E%E3%83%8D%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E3%81%AE%E3%81%99%E3%81%B9%E3%81%A6-%E4%BA%8B%E6%A5%AD%E6%88%A6%E7%95%A5%E3%83%BBIT%E9%96%8B%E7%99%BA%E3%83%BBUX%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%BB%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E3%81%8B%E3%82%89%E3%83%81%E3%83%BC%E3%83%A0%E3%83%BB%E7%B5%84%E7%B9%94%E9%81%8B%E5%96%B6%E3%81%BE%E3%81%A7-%E5%8F%8A%E5%B7%9D-%E5%8D%93%E4%B9%9F/dp/4798166391?_encoding=UTF8&qid=1623673323&sr=1-4&linkCode=li2&tag=htech-22&linkId=3d0ed7b8dd308a3923554cbca9cdb016&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4798166391&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4798166391" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  // `受託`
  {
    title: "「納品」をなくせばうまくいく",
    date: "",
    author: "",
    categories: ["受託"],
    url: "https://amzn.to/3uzgFwG",
    img: `<a href="https://www.amazon.co.jp/%E3%80%8C%E7%B4%8D%E5%93%81%E3%80%8D%E3%82%92%E3%81%AA%E3%81%8F%E3%81%9B%E3%81%B0%E3%81%86%E3%81%BE%E3%81%8F%E3%81%84%E3%81%8F-%E5%80%89%E8%B2%AB%E7%BE%A9%E4%BA%BA-ebook/dp/B00LSQ8G4W?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%80%8C%E7%B4%8D%E5%93%81%E3%80%8D%E3%82%92%E3%81%AA%E3%81%8F%E3%81%9B%E3%81%B0%E3%81%86%E3%81%BE%E3%81%8F%E3%81%84%E3%81%8F&qid=1623671578&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=792b0d8e6a52be6bc1fc5be3b826a47d&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00LSQ8G4W&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B00LSQ8G4W" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "管理ゼロで成果はあがる ~「見直す・なくす・やめる」で組織を変えよう",
    date: "",
    author: "",
    categories: ["受託"],
    url: "https://amzn.to/3iEjKcp",
    img: `<a href="https://www.amazon.co.jp/%E7%AE%A1%E7%90%86%E3%82%BC%E3%83%AD%E3%81%A7%E6%88%90%E6%9E%9C%E3%81%AF%E3%81%82%E3%81%8C%E3%82%8B-%E3%80%8C%E8%A6%8B%E7%9B%B4%E3%81%99%E3%83%BB%E3%81%AA%E3%81%8F%E3%81%99%E3%83%BB%E3%82%84%E3%82%81%E3%82%8B%E3%80%8D%E3%81%A7%E7%B5%84%E7%B9%94%E3%82%92%E5%A4%89%E3%81%88%E3%82%88%E3%81%86-%E5%80%89%E8%B2%AB-%E7%BE%A9%E4%BA%BA/dp/4297103583?_encoding=UTF8&qid=&sr=&linkCode=li2&tag=htech-22&linkId=64a016c381cb39eaed9b3b75d8ec7d3a&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4297103583&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4297103583" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "御社にそのシステムは不要です。 中小企業のための〝失敗しない〟IT戦略",
    date: "",
    author: "",
    categories: ["受託"],
    url: "https://amzn.to/2SwvXFu",
    img: `<a href="https://www.amazon.co.jp/%E5%BE%A1%E7%A4%BE%E3%81%AB%E3%81%9D%E3%81%AE%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E3%81%AF%E4%B8%8D%E8%A6%81%E3%81%A7%E3%81%99%E3%80%82-%E4%B8%AD%E5%B0%8F%E4%BC%81%E6%A5%AD%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE%E3%80%9D%E5%A4%B1%E6%95%97%E3%81%97%E3%81%AA%E3%81%84%E3%80%9FIT%E6%88%A6%E7%95%A5-%E5%9B%9B%E5%AE%AE%E9%9D%96%E9%9A%86/dp/4866672609?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0+%E4%B8%8D%E8%A6%81&qid=1623671644&sr=8-6&linkCode=li2&tag=htech-22&linkId=988099186702bf8dda89cff02dd6272f&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4866672609&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4866672609" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  
  // `ベンチャー`
  {
    title: "独立志向 昭和のITベンチャー起業家が語る仕事、家族、そして人生",
    date: "",
    author: "",
    categories: ["ベンチャー"],
    url: "https://amzn.to/3iMruci",
    img: `<a href="https://www.amazon.co.jp/dp/4344928105?psc=1&pd_rd_i=4344928105p13NParams&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzRVhTMkpBMUtMTUpXJmVuY3J5cHRlZElkPUEwMTc3NzIwMkQ4RkwyVlBXUTlEVyZlbmNyeXB0ZWRBZElkPUEzR042U1E2QU5ZRFROJndpZGdldE5hbWU9c3BfZGV0YWlsJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ%3D%3D&linkCode=li2&tag=htech-22&linkId=03dcd6bd38153d9950451f04783b5ccd&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4344928105&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4344928105" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "9割の社会問題はビジネスで解決できる",
    date: "",
    author: "",
    categories: ["ベンチャー"],
    url: "https://amzn.to/2SpS80c",
    img: `<a href="https://www.amazon.co.jp/9%E5%89%B2%E3%81%AE%E7%A4%BE%E4%BC%9A%E5%95%8F%E9%A1%8C%E3%81%AF%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%81%A7%E8%A7%A3%E6%B1%BA%E3%81%A7%E3%81%8D%E3%82%8B-%E7%94%B0%E5%8F%A3-%E4%B8%80%E6%88%90/dp/456984913X?_encoding=UTF8&qid=1623672113&sr=1-2&linkCode=li2&tag=htech-22&linkId=386185fd4a64b0a7d1a21bb0bcebfb95&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=456984913X&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=456984913X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "世界最速ビジネスモデル 中国スタートアップ図鑑",
    date: "",
    author: "",
    categories: ["ベンチャー"],
    url: "https://amzn.to/2U4wEq1",
    img: `<a href="https://www.amazon.co.jp/%E4%B8%96%E7%95%8C%E6%9C%80%E9%80%9F%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%A2%E3%83%87%E3%83%AB-%E4%B8%AD%E5%9B%BD%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%A2%E3%83%83%E3%83%97%E5%9B%B3%E9%91%91-%E4%BA%95%E4%B8%8A-%E9%81%94%E5%BD%A6/dp/4296000136?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%83%99%E3%83%B3%E3%83%81%E3%83%A3%E3%83%BC&qid=1623672113&refinements=p_n_publication_date%3A82838051&rnid=82836051&s=books&sr=1-5&linkCode=li2&tag=htech-22&linkId=4144a2050b41ed564137f87e4195010b&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4296000136&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4296000136" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  // `ビジネス`
  {
    title: `オードリー・タン デジタルとAIの未来を語る`,
    date: "",
    author: "",
    categories: [`ビジネス`],
    url: `https://amzn.to/3xjSEeJ`,
    img: `<a href="https://www.amazon.co.jp/%E3%82%AA%E3%83%BC%E3%83%89%E3%83%AA%E3%83%BC%E3%83%BB%E3%82%BF%E3%83%B3-%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E3%81%A8AI%E3%81%AE%E6%9C%AA%E6%9D%A5%E3%82%92%E8%AA%9E%E3%82%8B/dp/4833423995?pf_rd_r=RMSWQ2K7HKYZ5TJW2WQ2&pf_rd_p=309564c6-7435-57f3-8824-21bf29054cc7&pf_rd_s=merchandised-search-10&pf_rd_t=BROWSE&pf_rd_i=492332&linkCode=li2&tag=htech-22&linkId=70751db8e56af564c19d19559a05ab86&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4833423995&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4833423995" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `妄想する頭 思考する手 想像を超えるアイデアのつくり方`,
    date: "",
    author: "",
    categories: [`ビジネス`],
    url: `https://amzn.to/3cABz8z`,
    img: `<a href="https://www.amazon.co.jp/%E5%A6%84%E6%83%B3%E3%81%99%E3%82%8B%E9%A0%AD-%E6%80%9D%E8%80%83%E3%81%99%E3%82%8B%E6%89%8B-%E6%83%B3%E5%83%8F%E3%82%92%E8%B6%85%E3%81%88%E3%82%8B%E3%82%A2%E3%82%A4%E3%83%87%E3%82%A2%E3%81%AE%E3%81%A4%E3%81%8F%E3%82%8A%E6%96%B9-%E5%8D%98%E8%A1%8C%E6%9C%AC-%E6%9A%A6%E6%9C%AC/dp/4396617488?pf_rd_r=RS7EXBHRBB4A6C6CDM4K&pf_rd_p=7e368783-4adf-58dd-9962-49157bc43e0f&pf_rd_s=merchandised-search-19&pf_rd_t=BROWSE&pf_rd_i=466298&linkCode=li2&tag=htech-22&linkId=fc921d2993270b53b31f2d8d7fcfea13&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4396617488&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4396617488" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `起業の天才!: 江副浩正 8兆円企業リクルートをつくった男`,
    date: "",
    author: "",
    categories: [`ビジネス`],
    url: `https://amzn.to/3xlhLOw`,
    img: `<a href="https://www.amazon.co.jp/%E8%B5%B7%E6%A5%AD%E3%81%AE%E5%A4%A9%E6%89%8D-%E6%B1%9F%E5%89%AF%E6%B5%A9%E6%AD%A3-8%E5%85%86%E5%86%86%E4%BC%81%E6%A5%AD%E3%83%AA%E3%82%AF%E3%83%AB%E3%83%BC%E3%83%88%E3%82%92%E3%81%A4%E3%81%8F%E3%81%A3%E3%81%9F%E7%94%B7-%E5%A4%A7%E8%A5%BF-%E5%BA%B7%E4%B9%8B/dp/4492062165?pf_rd_r=BJ6CC8F5R599XSH8E8WK&pf_rd_p=309564c6-7435-57f3-8824-21bf29054cc7&pf_rd_s=merchandised-search-12&pf_rd_t=BROWSE&pf_rd_i=492332&linkCode=li2&tag=htech-22&linkId=ca2f531886e7a33c9d2ec735e20cdc57&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4492062165&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4492062165" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: "プロダクトマネジメントのすべて 事業戦略・IT開発・UXデザイン・マーケティングからチーム・組織運営まで",
    date: "2021/3/3",
    author: "及川 卓也",
    categories: ["ビジネス"],
    url: "https://amzn.to/35nBZOC",
    img: `<a href="https://www.amazon.co.jp/%E3%83%97%E3%83%AD%E3%83%80%E3%82%AF%E3%83%88%E3%83%9E%E3%83%8D%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E3%81%AE%E3%81%99%E3%81%B9%E3%81%A6-%E4%BA%8B%E6%A5%AD%E6%88%A6%E7%95%A5%E3%83%BBIT%E9%96%8B%E7%99%BA%E3%83%BBUX%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%BB%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E3%81%8B%E3%82%89%E3%83%81%E3%83%BC%E3%83%A0%E3%83%BB%E7%B5%84%E7%B9%94%E9%81%8B%E5%96%B6%E3%81%BE%E3%81%A7-%E5%8F%8A%E5%B7%9D-%E5%8D%93%E4%B9%9F-ebook/dp/B08W51KLQJ?pd_rd_i=B08W51KLQJ&pd_rd_r=aff25715-fa6e-4dd7-83a4-c2ecfe91a7bc&pd_rd_w=sfwFj&pd_rd_wg=aM63D&pf_rd_p=5140871e-1f0b-4eec-ae85-6fb986fe2b27&pf_rd_r=0H3V1P4C9S6E9BPN82AF&linkCode=li2&tag=htech-22&linkId=b7290555d6aa26b159a78c5582c6fc67&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08W51KLQJ&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B08W51KLQJ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  // `プログラミング`
  {
    title: "初めてのJavaScript 第3版 ―ES2015以降の最新ウェブ開発",
    date: "",
    author: "",
    categories: ["プログラミング"],
    url: "https://amzn.to/3ofPLr0",
    img: `<a href="https://www.amazon.co.jp/%E5%88%9D%E3%82%81%E3%81%A6%E3%81%AEJavaScript-%E7%AC%AC3%E7%89%88-%E2%80%95ES2015%E4%BB%A5%E9%99%8D%E3%81%AE%E6%9C%80%E6%96%B0%E3%82%A6%E3%82%A7%E3%83%96%E9%96%8B%E7%99%BA-Ethan-Brown/dp/4873117836?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E5%88%9D%E3%82%81%E3%81%A6%E3%81%AEJavaScript&qid=1623672979&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=d244d4c0d33e00eacd1f180cf35d1d88&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4873117836&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4873117836" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "ほんの一手間で劇的に変わるHTML & CSSとWebデザイン実践講座",
    date: "",
    author: "",
    categories: ["プログラミング"],
    url: "https://amzn.to/2U4vdrD",
    img: `<a href="https://www.amazon.co.jp/%E3%81%BB%E3%82%93%E3%81%AE%E4%B8%80%E6%89%8B%E9%96%93%E3%81%A7%E5%8A%87%E7%9A%84%E3%81%AB%E5%A4%89%E3%82%8F%E3%82%8BHTML-CSS%E3%81%A8Web%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E5%AE%9F%E8%B7%B5%E8%AC%9B%E5%BA%A7-Mana/dp/4815606145?pf_rd_r=GS47HF9EWH74A0RW3ZJC&pf_rd_p=dca234d5-93fe-5cbd-a5b7-1c9e6186b5bc&pf_rd_s=merchandised-search-10&pf_rd_t=BROWSE&pf_rd_i=3229701051&linkCode=li2&tag=htech-22&linkId=3b1a6ce785e3a35b59f28b687287ed38&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4815606145&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4815606145" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "仕組みと使い方がわかる Docker＆Kubernetesのきほんのきほん",
    date: "2013/1/26",
    author: "",
    categories: ["プログラミング"],
    url: "https://amzn.to/3guZtU0",
    img: `<a href="https://www.amazon.co.jp/%E4%BB%95%E7%B5%84%E3%81%BF%E3%81%A8%E4%BD%BF%E3%81%84%E6%96%B9%E3%81%8C%E3%82%8F%E3%81%8B%E3%82%8B-Docker-Kubernetes%E3%81%AE%E3%81%8D%E3%81%BB%E3%82%93%E3%81%AE%E3%81%8D%E3%81%BB%E3%82%93-%E5%B0%8F%E7%AC%A0%E5%8E%9F%E7%A8%AE%E9%AB%98/dp/4839972745?_encoding=UTF8&qid=1623672321&sr=1-4&linkCode=li2&tag=htech-22&linkId=8fff24311fcb5454430b1fbb24f9e318&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839972745&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4839972745" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "SQLアンチパターン",
    date: "",
    author: "",
    categories: ["プログラミング"],
    url: "https://amzn.to/3KyUpfv",
    img: `<a href="https://www.amazon.co.jp/SQL%E3%82%A2%E3%83%B3%E3%83%81%E3%83%91%E3%82%BF%E3%83%BC%E3%83%B3-Bill-Karwin/dp/4873115892?&linkCode=li2&tag=htech-22&linkId=6cb277e62e1b1bc7b49caaedf937889a&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4873115892&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4873115892" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "Python FlaskによるWebアプリ開発入門 物体検知アプリ&機械学習APIの作り方",
    date: "2022/1/24",
    author: "",
    categories: ["プログラミング"],
    url: "https://amzn.to/3GZ1Daw",
    img: `<a href="https://www.amazon.co.jp/Python-Flask%E3%81%AB%E3%82%88%E3%82%8BWeb%E3%82%A2%E3%83%97%E3%83%AA%E9%96%8B%E7%99%BA%E5%85%A5%E9%96%80-%E7%89%A9%E4%BD%93%E6%A4%9C%E7%9F%A5%E3%82%A2%E3%83%97%E3%83%AA-%E6%A9%9F%E6%A2%B0%E5%AD%A6%E7%BF%92API%E3%81%AE%E4%BD%9C%E3%82%8A%E6%96%B9-%E4%BD%90%E8%97%A4/dp/4798166464?pd_rd_i=4798166464&psc=1&linkCode=li2&tag=htech-22&linkId=53b199ebc840c2fd9ce92f3022f63bdc&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4798166464&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4798166464" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "ゼロから作る Deep Learning ❹",
    date: "2022/4/6",
    author: "",
    categories: ["プログラミング"],
    url: "https://amzn.to/3GYcilG",
    img: `<a href="https://www.amazon.co.jp/%E3%82%BC%E3%83%AD%E3%81%8B%E3%82%89%E4%BD%9C%E3%82%8B-Deep-Learning-%E2%9D%B9-%E2%80%95%E5%BC%B7%E5%8C%96%E5%AD%A6%E7%BF%92%E7%B7%A8/dp/4873119758?pd_rd_i=4873119758&psc=1&linkCode=li2&tag=htech-22&linkId=35910bd763d771ad47e40ea8f0f61668&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4873119758&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4873119758" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "Code as Creative Medium [コード・アズ・クリエイティブ・メディウム] 創造的なプログラミング教育のための実践ガイドブック",
    date: "2022/1/25",
    author: "",
    categories: ["プログラミング"],
    url: "https://amzn.to/3fNX9Yb",
    img: `<a href="https://www.amazon.co.jp/Code-Creative-Medium-%E3%82%B3%E3%83%BC%E3%83%89%E3%83%BB%E3%82%A2%E3%82%BA%E3%83%BB%E3%82%AF%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%86%E3%82%A3%E3%83%96%E3%83%BB%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A6%E3%83%A0-%E5%89%B5%E9%80%A0%E7%9A%84%E3%81%AA%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%E6%95%99%E8%82%B2%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE%E5%AE%9F%E8%B7%B5%E3%82%AC%E3%82%A4%E3%83%89%E3%83%96%E3%83%83%E3%82%AF/dp/4802510128?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=U709X67EQ6SN&keywords=%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0&qid=1642948243&refinements=p_n_publication_date%3A2285541051&rnid=82836051&s=books&sprefix=%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%2Cstripbooks%2C311&sr=1-6&linkCode=li2&tag=htech-22&linkId=fbb2510a400cc619ebf0f75d91437257&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4802510128&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4802510128" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "Rが生産性を高める〜データ分析ワークフロー効率化の実践",
    date: "2022/1/26",
    author: "",
    categories: ["プログラミング"],
    url: "https://amzn.to/35hY3dF",
    img: `<a href="https://www.amazon.co.jp/R%E3%81%8C%E7%94%9F%E7%94%A3%E6%80%A7%E3%82%92%E9%AB%98%E3%82%81%E3%82%8B%E3%80%9C%E3%83%87%E3%83%BC%E3%82%BF%E5%88%86%E6%9E%90%E3%83%AF%E3%83%BC%E3%82%AF%E3%83%95%E3%83%AD%E3%83%BC%E5%8A%B9%E7%8E%87%E5%8C%96%E3%81%AE%E5%AE%9F%E8%B7%B5-igjit/dp/4297125242?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=U709X67EQ6SN&keywords=%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0&qid=1642948310&refinements=p_n_publication_date%3A2285541051&rnid=82836051&s=books&sprefix=%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%2Cstripbooks%2C311&sr=1-21&linkCode=li2&tag=htech-22&linkId=2dd6cd37b84d04769ee366c82e51acd5&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4297125242&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4297125242" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  /*
  {
    title: "",
    date: "",
    author: "",
    categories: ["プログラミング"],
    url: "",
    img: ``,
    description: "",
  },
  */
  // デザイン
  {
    title: `ノンデザイナーズ・デザインブック　［第4版］`,
    date: "2016/9/20",
    author: "Robin Williams、吉川 典秀、小原 司、米谷 テツヤ",
    categories: [`デザイン`],
    url: `https://amzn.to/3zTDcaO`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%8E%E3%83%B3%E3%83%87%E3%82%B6%E3%82%A4%E3%83%8A%E3%83%BC%E3%82%BA%E3%83%BB%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%96%E3%83%83%E3%82%AF-%EF%BC%BB%E7%AC%AC4%E7%89%88%EF%BC%BD-Robin-Williams-ebook/dp/B01LW1BC2L?_encoding=UTF8&qid=1631015826&sr=8-1&linkCode=li2&tag=htech-22&linkId=24a3bdc1adae09f8a4f4d79c2d3bf369&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01LW1BC2L&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B01LW1BC2L" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `なるほどデザイン`,
    date: "2015/7/31",
    author: "筒井 美希",
    categories: [`デザイン`],
    url: `https://amzn.to/2YtQzkh`,
    img: `<a href="https://www.amazon.co.jp/%E3%81%AA%E3%82%8B%E3%81%BB%E3%81%A9%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3-%E7%AD%92%E4%BA%95-%E7%BE%8E%E5%B8%8C-ebook/dp/B012VJNW6Q?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=BUDVRG2SXKDA&dchild=1&keywords=%E3%81%AA%E3%82%8B%E3%81%BB%E3%81%A9%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3&qid=1631015951&sprefix=%E3%81%AA%E3%82%8B%E3%81%BB%E3%81%A9%2Caps%2C140&sr=8-2-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzMVVETk5BOEsyMlYxJmVuY3J5cHRlZElkPUEwMzE0MzY2MUtQTlJYMVRYRlI2TyZlbmNyeXB0ZWRBZElkPUEzODdTVFg3TDAzV1ZSJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ%3D%3D&linkCode=li2&tag=htech-22&linkId=cd7d2d57bb7564871b7f15d2bebbb9bd&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B012VJNW6Q&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B012VJNW6Q" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `大人女子デザイン 女性の心を動かすデザインアイデア53`,
    date: "2020/1/15",
    author: "ingectar-e",
    categories: [`デザイン`],
    url: `https://amzn.to/3zS6xTe`,
    img: `<a href="https://www.amazon.co.jp/%E5%A4%A7%E4%BA%BA%E5%A5%B3%E5%AD%90%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3-%E5%A5%B3%E6%80%A7%E3%81%AE%E5%BF%83%E3%82%92%E5%8B%95%E3%81%8B%E3%81%99%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%82%A2%E3%82%A4%E3%83%87%E3%82%A253-ingectar-ebook/dp/B081GCSS49?_encoding=UTF8&qid=1631016004&sr=8-1&linkCode=li2&tag=htech-22&linkId=a4a57eaab18db771068a51875e37390b&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081GCSS49&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B081GCSS49" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `フォントのふしぎ ブランドのロゴはなぜ高そうに見えるのか？`,
    date: "2011/1/17",
    author: "小林 章",
    categories: [`デザイン`],
    url: `小林 章`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%95%E3%82%A9%E3%83%B3%E3%83%88%E3%81%AE%E3%81%B5%E3%81%97%E3%81%8E-%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%81%AE%E3%83%AD%E3%82%B4%E3%81%AF%E3%81%AA%E3%81%9C%E9%AB%98%E3%81%9D%E3%81%86%E3%81%AB%E8%A6%8B%E3%81%88%E3%82%8B%E3%81%AE%E3%81%8B%EF%BC%9F-%E5%B0%8F%E6%9E%97-%E7%AB%A0/dp/4568504287?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%83%95%E3%82%A9%E3%83%B3%E3%83%88%E3%81%AE%E3%81%B5%E3%81%97%E3%81%8E&qid=1631016062&sr=8-1&linkCode=li2&tag=htech-22&linkId=97849d26e3ff33949c8edb94a31e1397&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4568504287&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4568504287" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `Webデザイン良質見本帳　目的別に探せて、すぐに使えるアイデア集`,
    date: "2017/6/15",
    author: "久保田 涼子",
    categories: [`デザイン`],
    url: `https://amzn.to/3BIRJXm`,
    img: `<a href="https://www.amazon.co.jp/Web%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E8%89%AF%E8%B3%AA%E8%A6%8B%E6%9C%AC%E5%B8%B3-%E7%9B%AE%E7%9A%84%E5%88%A5%E3%81%AB%E6%8E%A2%E3%81%9B%E3%81%A6%E3%80%81%E3%81%99%E3%81%90%E3%81%AB%E4%BD%BF%E3%81%88%E3%82%8B%E3%82%A2%E3%82%A4%E3%83%87%E3%82%A2%E9%9B%86-%E4%B9%85%E4%BF%9D%E7%94%B0-%E6%B6%BC%E5%AD%90-ebook/dp/B071KDLRGV?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2GPA5QT8M2QET&dchild=1&keywords=web%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E8%89%AF%E8%B3%AA%E8%A6%8B%E6%9C%AC%E5%B8%B3&qid=1631016113&sprefix=Web%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%2Caps%2C120&sr=8-1&linkCode=li2&tag=htech-22&linkId=bbd993f163271a48a04d40dea8565e2c&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B071KDLRGV&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B071KDLRGV" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `COLOR DESIGN カラー別配色デザインブック`,
    date: "2021/2/10",
    author: "ingectar-e",
    categories: [`デザイン`],
    url: `https://amzn.to/2VXJNSE`,
    img: `<a href="https://www.amazon.co.jp/COLOR-DESIGN-%E3%82%AB%E3%83%A9%E3%83%BC%E5%88%A5%E9%85%8D%E8%89%B2%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%96%E3%83%83%E3%82%AF-ingectar/dp/4046049871?&linkCode=li2&tag=htech-22&linkId=7a67b242c7a92b57d0f1c7c8005b7db0&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4046049871&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4046049871" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `#ズボラPhotoshop 知識いらずの絶品3分デザイン`,
    date: "2020/10/1",
    author: "トミナガハルキ",
    categories: [`デザイン`],
    url: `https://amzn.to/2WW9JyH`,
    img: `<a href="https://www.amazon.co.jp/%E3%82%BA%E3%83%9C%E3%83%A9Photoshop-%E7%9F%A5%E8%AD%98%E3%81%84%E3%82%89%E3%81%9A%E3%81%AE%E7%B5%B6%E5%93%813%E5%88%86%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3-%E3%83%88%E3%83%9F%E3%83%8A%E3%82%AC%E3%83%8F%E3%83%AB%E3%82%AD-ebook/dp/B08KCMVNGP?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%81%9A%E3%81%BC%E3%82%89photoshop&qid=1631016318&sr=8-1&linkCode=li2&tag=htech-22&linkId=d76696cfd6ce5b3ed8490fc6ab6a1ad9&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08KCMVNGP&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B08KCMVNGP" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `豊富な作例で学ぶ Adobe XD Webデザイン入門`,
    date: "2021/3/22",
    author: "池原健治  (著), 井斉花織  (著), 佐々木雄平 (著), 佐藤修 (著), 田中由花 (著), その他",
    categories: [`デザイン`],
    url: `https://amzn.to/3mzc1Ol`,
    img: `<a href="https://www.amazon.co.jp/%E8%B1%8A%E5%AF%8C%E3%81%AA%E4%BD%9C%E4%BE%8B%E3%81%A7%E5%AD%A6%E3%81%B6-Adobe-Web%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E5%85%A5%E9%96%80-Compass-Books%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA/dp/4839975353?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=Adobe+XD&qid=1635775775&sr=8-3&linkCode=li2&tag=htech-22&linkId=45b8517ec29989b923b36db970718fd0&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839975353&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4839975353" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `ロゴデザインの教科書 良質な見本から学べるすぐに使えるアイデア帳`,
    date: "2020/7/22",
    author: "植田 阿希 (著)",
    categories: [`デザイン`],
    url: `https://amzn.to/3bw8BWq`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%AD%E3%82%B4%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%81%AE%E6%95%99%E7%A7%91%E6%9B%B8-%E4%BD%9C%E3%82%8A%E6%96%B9%E3%81%AE%E5%9F%BA%E6%9C%AC%E3%81%8B%E3%82%89%E5%B1%95%E9%96%8B%E6%96%B9%E6%B3%95%E3%81%BE%E3%81%A7%E3%81%8D%E3%81%A1%E3%82%93%E3%81%A8%E5%AD%A6%E3%81%B9%E3%82%8B%E6%9C%AC-%E3%82%AA%E3%83%96%E3%82%B9%E3%82%AD%E3%83%A5%E3%82%A2%E3%82%A4%E3%83%B3%E3%82%AF/dp/4797394463?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%83%AD%E3%82%B4%E5%88%B6%E4%BD%9C&qid=1635775920&sr=8-5&linkCode=li2&tag=htech-22&linkId=a2dc2841bcedfc8b3d0bfdc9a9a6f029&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4797394463&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4797394463" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `デザイン3000`,
    date: "2021/10/11",
    author: "大谷 秀映 (著), 杉江 耕平 (著), ハラ ヒロシ  (著), ハヤシ アキコ  (著), その他",
    categories: [`デザイン`],
    url: `https://amzn.to/3myiLMB`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B33000-%E5%A4%A7%E8%B0%B7-%E7%A7%80%E6%98%A0/dp/4798172456?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=24C3JR5S6YR88&dchild=1&keywords=%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B33000&qid=1635775975&sprefix=%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B33000%2Caps%2C279&sr=8-1&linkCode=li2&tag=htech-22&linkId=3ce5846f1617eb65d4280b9d7e7e1085&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4798172456&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4798172456" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `Web制作者のためのUXデザインをはじめる本 ユーザビリティ評価からカスタマージャーニーマップまで`,
    date: "2016/11/15",
    author: "玉飼 真一  (著), 村上 竜介 (著), 佐藤 哲 (著), 太田 文明 (著), 常盤 晋作 (著), その他",
    categories: [`デザイン`],
    url: `https://amzn.to/3Cv7vpL`,
    img: `<a href="https://www.amazon.co.jp/Web%E5%88%B6%E4%BD%9C%E8%80%85%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AEUX%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%82%92%E3%81%AF%E3%81%98%E3%82%81%E3%82%8B%E6%9C%AC-%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3%E8%A9%95%E4%BE%A1%E3%81%8B%E3%82%89%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%9E%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%83%8B%E3%83%BC%E3%83%9E%E3%83%83%E3%83%97%E3%81%BE%E3%81%A7-%E7%8E%89%E9%A3%BC-%E7%9C%9F%E4%B8%80/dp/4798143332?dchild=1&keywords=web%E8%A3%BD%E4%BD%9C%E8%80%85%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE&qid=1635776084&sprefix=Web%E8%A3%BD%E4%BD%9C%E8%80%85%2Caps%2C205&sr=8-5&linkCode=li2&tag=htech-22&linkId=f838dc0e5eb4383697b60926e6d4afef&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4798143332&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4798143332" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `現場で使える Webデザインアイデアレシピ`,
    date: "2022/1/31",
    author: "小林 マサユキ",
    categories: [`デザイン`],
    url: `https://amzn.to/3tOKeNV`,
    img: `<a href="https://www.amazon.co.jp/%E7%8F%BE%E5%A0%B4%E3%81%A7%E4%BD%BF%E3%81%88%E3%82%8B-Web%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%82%A2%E3%82%A4%E3%83%87%E3%82%A2%E3%83%AC%E3%82%B7%E3%83%94-%E5%B0%8F%E6%9E%97-%E3%83%9E%E3%82%B5%E3%83%A6%E3%82%AD/dp/4839977356?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=1V2B15FDV5S30&keywords=%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3&qid=1642948418&refinements=p_n_publication_date%3A2285541051&rnid=82836051&s=books&sprefix=%E3%81%A7%E3%81%96%E3%81%84n%2Cstripbooks%2C319&sr=1-2&linkCode=li2&tag=htech-22&linkId=b6832668f7a268997f474c8d4204c404&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4839977356&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4839977356" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `一生懸命デザインしたのにプロっぽくなりません。`,
    date: "2022/1/27",
    author: "シブヤ領一(ぱちぱち)",
    categories: [`デザイン`],
    url: `https://amzn.to/3FU500Y`,
    img: `<a href="https://www.amazon.co.jp/%E4%B8%80%E7%94%9F%E6%87%B8%E5%91%BD%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%81%97%E3%81%9F%E3%81%AE%E3%81%AB%E3%83%97%E3%83%AD%E3%81%A3%E3%81%BD%E3%81%8F%E3%81%AA%E3%82%8A%E3%81%BE%E3%81%9B%E3%82%93%E3%80%82-%E3%82%B7%E3%83%96%E3%83%A4%E9%A0%98%E4%B8%80-%E3%81%B1%E3%81%A1%E3%81%B1%E3%81%A1/dp/4798172588?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=1V2B15FDV5S30&keywords=%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3&qid=1642948418&refinements=p_n_publication_date%3A2285541051&rnid=82836051&s=books&sprefix=%E3%81%A7%E3%81%96%E3%81%84n%2Cstripbooks%2C319&sr=1-4&linkCode=li2&tag=htech-22&linkId=7e8167d1703672e5da8f6d77470969e0&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4798172588&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4798172588" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `思わずクリックしたくなる バナーデザインのきほん`,
    date: "2022/2/9",
    author: "カトウ ヒカル",
    categories: [`デザイン`],
    url: `https://amzn.to/3IpfKpK`,
    img: `<a href="https://www.amazon.co.jp/%E3%80%90%E6%9C%9F%E9%96%93%E9%99%90%E5%AE%9A%E7%89%B9%E5%85%B8%E3%81%82%E3%82%8A%E3%80%91%E6%80%9D%E3%82%8F%E3%81%9A%E3%82%AF%E3%83%AA%E3%83%83%E3%82%AF%E3%81%97%E3%81%9F%E3%81%8F%E3%81%AA%E3%82%8B-%E3%83%90%E3%83%8A%E3%83%BC%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%81%AE%E3%81%8D%E3%81%BB%E3%82%93-%E3%82%AB%E3%83%88%E3%82%A6-%E3%83%92%E3%82%AB%E3%83%AB/dp/4295013277?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=1V2B15FDV5S30&keywords=%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3&qid=1642948418&refinements=p_n_publication_date%3A2285541051&rnid=82836051&s=books&sprefix=%E3%81%A7%E3%81%96%E3%81%84n%2Cstripbooks%2C319&sr=1-12&linkCode=li2&tag=htech-22&linkId=7e4938fcfd6274869ad0f14ff043950f&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4295013277&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4295013277" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `デザイン初心者のためのPhotoshop Illustrator 先輩に聞かずに9割解決できるグラフィックデザイン超基礎`,
    date: "2022/1/25",
    author: "",
    categories: [`デザイン`],
    url: `https://amzn.to/3rORuXM`,
    img: `<a href="https://www.amazon.co.jp/DL%E3%83%87%E3%83%BC%E3%82%BF%E4%BB%98-%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E5%88%9D%E5%BF%83%E8%80%85%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AEPhotoshop-Illustrator-%E5%85%88%E8%BC%A9%E3%81%AB%E8%81%9E%E3%81%8B%E3%81%9A%E3%81%AB9%E5%89%B2%E8%A7%A3%E6%B1%BA%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E8%B6%85%E5%9F%BA%E7%A4%8E/dp/4295013218?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=1V2B15FDV5S30&keywords=%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3&qid=1642948504&refinements=p_n_publication_date%3A2285541051&rnid=82836051&s=books&sprefix=%E3%81%A7%E3%81%96%E3%81%84n%2Cstripbooks%2C319&sr=1-17&linkCode=li2&tag=htech-22&linkId=f6011db11a7556d20b1e1519382d4eb8&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4295013218&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4295013218" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `とりあえず、素人っぽく見えないデザインのコツを教えてください!`,
    date: "2022/1/19",
    author: "ingectar-e",
    categories: [`デザイン`],
    url: `https://amzn.to/3nRfjgi`,
    img: `<a href="https://www.amazon.co.jp/%E3%81%A8%E3%82%8A%E3%81%82%E3%81%88%E3%81%9A%E3%80%81%E7%B4%A0%E4%BA%BA%E3%81%A3%E3%81%BD%E3%81%8F%E8%A6%8B%E3%81%88%E3%81%AA%E3%81%84%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%81%AE%E3%82%B3%E3%83%84%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84-ingectar/dp/429501317X?qid=1642948808&refinements=p_27%3Aingectar-e&s=books&sr=1-3&text=ingectar-e&linkCode=li2&tag=htech-22&linkId=fea496cb5cdf9f92b96de100c3d86889&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=429501317X&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=429501317X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `レタースペーシング タイポグラフィにおける文字間調整の考え方`,
    date: "2021/7/21",
    author: "今市達也",
    categories: [`デザイン`],
    url: `https://amzn.to/3h8U2uO`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%AC%E3%82%BF%E3%83%BC%E3%82%B9%E3%83%9A%E3%83%BC%E3%82%B7%E3%83%B3%E3%82%B0-%E3%82%BF%E3%82%A4%E3%83%9D%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E6%96%87%E5%AD%97%E9%96%93%E8%AA%BF%E6%95%B4%E3%81%AE%E8%80%83%E3%81%88%E6%96%B9-%E4%BB%8A%E5%B8%82%E9%81%94%E4%B9%9F/dp/4802512090?_encoding=UTF8&pd_rd_w=Rxar1&pf_rd_p=81541518-e90a-4afb-9e0c-f54d7e35a763&pf_rd_r=MXNBJTJVR3MDFNAF6XF4&pd_rd_r=827e739a-942f-4d7e-9152-0c1f93581a5b&pd_rd_wg=8qxou&linkCode=li2&tag=htech-22&linkId=366c4528363dffad8bba647f731a95ac&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4802512090&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4802512090" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  /*
  {
    title: ``,
    date: "",
    author: "",
    categories: [`デザイン`],
    url: ``,
    img: ``,
    description: ``,
  },
  */
  // VR
  {
    title: "バーチャル・リアリティ百科:進化するVRの現在と可能性",
    date: "2021/10/22",
    author: "",
    categories: ["VR"],
    url: "https://amzn.to/3pEqkmE",
    img: `<a href="https://www.amazon.co.jp/%E3%83%93%E3%82%B8%E3%83%A5%E3%82%A2%E3%83%AB%E7%89%88-%E3%83%90%E3%83%BC%E3%83%81%E3%83%A3%E3%83%AB%E3%83%BB%E3%83%AA%E3%82%A2%E3%83%AA%E3%83%86%E3%82%A3%E7%99%BE%E7%A7%91-%E9%80%B2%E5%8C%96%E3%81%99%E3%82%8BVR%E3%81%AE%E7%8F%BE%E5%9C%A8%E3%81%A8%E5%8F%AF%E8%83%BD%E6%80%A7-%E3%82%A4%E3%83%B3%E3%83%87%E3%82%A3%E3%83%A9%E3%83%BB%E3%83%88%E3%82%A5%E3%83%BC%E3%83%B4%E3%82%A7%E3%83%8B%E3%83%B3/dp/4562059591?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=VR&qid=1635250015&s=books&sr=1-16&linkCode=li2&tag=htech-22&linkId=c8af27651c8bee6677eb8952652cde6f&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4562059591&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4562059591" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "未来ビジネス図解 仮想空間とVR",
    date: "2021/3/25",
    author: "",
    categories: ["VR"],
    url: "https://amzn.to/3jCtNi6",
    img: `<a href="https://www.amazon.co.jp/%E6%9C%AA%E6%9D%A5%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E5%9B%B3%E8%A7%A3-%E4%BB%AE%E6%83%B3%E7%A9%BA%E9%96%93%E3%81%A8VR-%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E5%BE%80%E6%9D%A5/dp/4295200948?pd_rd_w=7G6zs&pf_rd_p=e2d8154e-eaa7-4e5c-92b5-da933ded21eb&pf_rd_r=S4QJA8Z3CC7BTC8760Y3&pd_rd_r=483f4cfd-e04b-4e0f-b9a7-ad84e910ae59&pd_rd_wg=eF0bt&pd_rd_i=4295200948&psc=1&linkCode=li2&tag=htech-22&linkId=b30eb54e3d4ca080edd855fd7476a4c3&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4295200948&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4295200948" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "ミライをつくろう! VRで紡ぐバーチャル創世記",
    date: "2020/6/24",
    author: "GOROman (著)、西田 宗千佳  (編集)",
    categories: ["VR"],
    url: "https://amzn.to/3GyKgOb",
    img: `<a href="https://www.amazon.co.jp/%E3%83%9F%E3%83%A9%E3%82%A4%E3%82%92%E3%81%A4%E3%81%8F%E3%82%8D%E3%81%86-VR%E3%81%A7%E7%B4%A1%E3%81%90%E3%83%90%E3%83%BC%E3%83%81%E3%83%A3%E3%83%AB%E5%89%B5%E4%B8%96%E8%A8%98-GOROman/dp/4798165964?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=VR&qid=1635250191&s=books&sr=1-24&linkCode=li2&tag=htech-22&linkId=60c7bdc6104d185e5f00ec25ed09a8b0&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4798165964&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4798165964" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "ミライのつくり方2020―2045 僕がVRに賭けるわけ",
    date: "2018/4/27",
    author: "GOROman(近藤義仁) (著)、西田 宗千佳  (著)",
    categories: ["VR"],
    url: "https://amzn.to/3jXpsWX",
    img: `<a href="https://www.amazon.co.jp/%E3%83%9F%E3%83%A9%E3%82%A4%E3%81%AE%E3%81%A4%E3%81%8F%E3%82%8A%E6%96%B92020%E2%80%952045-%E5%83%95%E3%81%8CVR%E3%81%AB%E8%B3%AD%E3%81%91%E3%82%8B%E3%82%8F%E3%81%91-%E6%98%9F%E6%B5%B7%E7%A4%BE%E6%96%B0%E6%9B%B8-GOROman-%E8%BF%91%E8%97%A4%E7%BE%A9%E4%BB%81/dp/4065116511?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=VR&qid=1635250280&s=books&sr=1-101&linkCode=li2&tag=htech-22&linkId=b82a3dee6cea9399949a95f0ef134ac6&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4065116511&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4065116511" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "VRは脳をどう変えるか？　仮想現実の心理学",
    date: "2018/8/8",
    author: "ジェレミー・ベイレンソン (著)、倉田 幸信  (翻訳) ",
    categories: ["VR"],
    url: "https://amzn.to/3nAYB3Y",
    img: `<a href="https://www.amazon.co.jp/VR%E3%81%AF%E8%84%B3%E3%82%92%E3%81%A9%E3%81%86%E5%A4%89%E3%81%88%E3%82%8B%E3%81%8B%EF%BC%9F-%E4%BB%AE%E6%83%B3%E7%8F%BE%E5%AE%9F%E3%81%AE%E5%BF%83%E7%90%86%E5%AD%A6-%E6%96%87%E6%98%A5e-book-%E3%82%B8%E3%82%A7%E3%83%AC%E3%83%9F%E3%83%BC%E3%83%BB%E3%83%99%E3%82%A4%E3%83%AC%E3%83%B3%E3%82%BD%E3%83%B3-ebook/dp/B07G764QKM?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=VR+%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9&qid=1635251060&s=books&sr=1-23&linkCode=li2&tag=htech-22&linkId=6a1d06ea0fcfdba78267dd086ae6fb0f&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07G764QKM&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B07G764QKM" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  /*
  {
    title: "",
    date: "",
    author: "",
    categories: ["VR"],
    url: "",
    img: ``,
    description: "",
  },
  */

  // `マーケティング`
  {
    title: "新訳 ハイパワー・マーケティング　あなたのビジネスを加速させる「力」の見つけ方",
    date: "",
    author: "",
    categories: ["マーケティング"],
    url: "https://amzn.to/3pYQyPa",
    img: `<a href="https://www.amazon.co.jp/%E6%96%B0%E8%A8%B3-%E3%83%8F%E3%82%A4%E3%83%91%E3%83%AF%E3%83%BC%E3%83%BB%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0-%E3%81%82%E3%81%AA%E3%81%9F%E3%81%AE%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%82%92%E5%8A%A0%E9%80%9F%E3%81%95%E3%81%9B%E3%82%8B%E3%80%8C%E5%8A%9B%E3%80%8D%E3%81%AE%E8%A6%8B%E3%81%A4%E3%81%91%E6%96%B9-%E8%A7%92%E5%B7%9D%E6%9B%B8%E5%BA%97%E5%8D%98%E8%A1%8C%E6%9C%AC-%E3%82%B8%E3%82%A7%E3%82%A4%E3%83%BB%E3%82%A8%E3%82%A4%E3%83%96%E3%83%A9%E3%83%8F%E3%83%A0-ebook/dp/B076PY9Y8X?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0&qid=1623672831&s=books&sr=1-5&linkCode=li2&tag=htech-22&linkId=7033cf8799ab1781e29a63e199d1727f&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B076PY9Y8X&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B076PY9Y8X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "ビジネスフレームワーク図鑑 すぐ使える問題解決・アイデア発想ツール70",
    date: "",
    author: "",
    categories: ["マーケティング"],
    url: "https://amzn.to/3uGi1GL",
    img: `<a href="https://www.amazon.co.jp/%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AF%E3%83%BC%E3%82%AF%E5%9B%B3%E9%91%91-%E3%81%99%E3%81%90%E4%BD%BF%E3%81%88%E3%82%8B%E5%95%8F%E9%A1%8C%E8%A7%A3%E6%B1%BA%E3%83%BB%E3%82%A2%E3%82%A4%E3%83%87%E3%82%A2%E7%99%BA%E6%83%B3%E3%83%84%E3%83%BC%E3%83%AB70-%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E3%82%A2%E3%83%B3%E3%83%89/dp/4798156914?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AF%E3%83%BC%E3%82%AF%E5%9B%B3%E9%91%91+%E3%81%99%E3%81%90%E4%BD%BF%E3%81%88%E3%82%8B%E5%95%8F%E9%A1%8C%E8%A7%A3%E6%B1%BA%E3%83%BB%E3%82%A2%E3%82%A4%E3%83%87%E3%82%A2%E7%99%BA%E6%83%B3%E3%83%84%E3%83%BC%E3%83%AB70&qid=1623672912&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=a495ff61d4d51f081a38fadc319c2952&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4798156914&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4798156914" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "この1冊ですべてわかる 新版 マーケティングの基本",
    date: "",
    author: "",
    categories: ["マーケティング"],
    url: "https://amzn.to/3o6R90j",
    img: `<a href="https://www.amazon.co.jp/%E3%81%93%E3%81%AE1%E5%86%8A%E3%81%A7%E3%81%99%E3%81%B9%E3%81%A6%E3%82%8F%E3%81%8B%E3%82%8B-%E6%96%B0%E7%89%88-%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E3%81%AE%E5%9F%BA%E6%9C%AC-%E5%AE%89%E5%8E%9F-%E6%99%BA%E6%A8%B9/dp/4534056095?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%81%93%E3%81%AE1%E5%86%8A%E3%81%A7%E3%81%99%E3%81%B9%E3%81%A6%E3%82%8F%E3%81%8B%E3%82%8B+%E6%96%B0%E7%89%88+%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0&qid=1623672954&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=2b1d725b6ba204d8b39786647c426bef&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4534056095&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4534056095" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  // `セキュリティ`
  {
    title: "図解即戦力 情報セキュリティの技術と対策がこれ1冊でしっかりわかる教科書",
    date: "",
    author: "",
    categories: ["セキュリティ"],
    url: "https://amzn.to/3xjVKzn",
    img: `<a href="https://www.amazon.co.jp/%E5%9B%B3%E8%A7%A3%E5%8D%B3%E6%88%A6%E5%8A%9B-%E6%83%85%E5%A0%B1%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%81%AE%E6%8A%80%E8%A1%93%E3%81%A8%E5%AF%BE%E7%AD%96%E3%81%8C%E3%81%93%E3%82%8C1%E5%86%8A%E3%81%A7%E3%81%97%E3%81%A3%E3%81%8B%E3%82%8A%E3%82%8F%E3%81%8B%E3%82%8B%E6%95%99%E7%A7%91%E6%9B%B8-%E4%B8%AD%E6%9D%91-%E8%A1%8C%E5%AE%8F/dp/4297121069?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3&qid=1623672561&refinements=p_n_publication_date%3A82838051&rnid=82836051&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=fd576721a9f84e7462e8de51a021b071&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4297121069&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4297121069" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "決定版 サイバーセキュリティ: 新たな脅威と防衛策",
    date: "",
    author: "",
    categories: ["セキュリティ"],
    url: "https://amzn.to/3xuOD7B",
    img: `<a href="https://www.amazon.co.jp/%E6%B1%BA%E5%AE%9A%E7%89%88-%E3%82%B5%E3%82%A4%E3%83%90%E3%83%BC%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3-%E6%96%B0%E3%81%9F%E3%81%AA%E8%84%85%E5%A8%81%E3%81%A8%E9%98%B2%E8%A1%9B%E7%AD%96-Blue-Planet%E2%80%90works/dp/4492762450?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E6%B1%BA%E5%AE%9A%E7%89%88+%E3%82%B5%E3%82%A4%E3%83%90%E3%83%BC%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%3A+%E6%96%B0%E3%81%9F%E3%81%AA%E8%84%85%E5%A8%81%E3%81%A8%E9%98%B2%E8%A1%9B%E7%AD%96&qid=1623672500&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=09a233edd55da182456c7fde4e947974&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4492762450&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4492762450" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "事例に学ぶサイバーセキュリティ-多様化する脅威への対策と法務対応",
    date: "",
    author: "",
    categories: ["セキュリティ"],
    url: "https://amzn.to/35hcF9M",
    img: `<a href="https://www.amazon.co.jp/%E4%BA%8B%E4%BE%8B%E3%81%AB%E5%AD%A6%E3%81%B6%E3%82%B5%E3%82%A4%E3%83%90%E3%83%BC%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3-%E5%A4%9A%E6%A7%98%E5%8C%96%E3%81%99%E3%82%8B%E8%84%85%E5%A8%81%E3%81%B8%E3%81%AE%E5%AF%BE%E7%AD%96%E3%81%A8%E6%B3%95%E5%8B%99%E5%AF%BE%E5%BF%9C-%E5%A2%97%E5%B3%B6-%E9%9B%85%E5%92%8C/dp/4818519278?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E4%BA%8B%E4%BE%8B%E3%81%AB%E5%AD%A6%E3%81%B6%E3%82%B5%E3%82%A4%E3%83%90%E3%83%BC%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3-%E5%A4%9A%E6%A7%98%E5%8C%96%E3%81%99%E3%82%8B%E8%84%85%E5%A8%81%E3%81%B8%E3%81%AE%E5%AF%BE%E7%AD%96%E3%81%A8%E6%B3%95%E5%8B%99%E5%AF%BE%E5%BF%9C&qid=1623672529&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=ee1fd33ad343c504a5b1e4dd43ae98ef&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4818519278&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4818519278" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  // `キャリア`
  {
    title: "転職2.0 日本人のキャリアの新・ルール",
    date: "",
    author: "",
    categories: ["キャリア"],
    url: "https://amzn.to/3iEUIK9",
    img: `<a href="https://www.amazon.co.jp/%E8%BB%A2%E8%81%B72-0-%E6%97%A5%E6%9C%AC%E4%BA%BA%E3%81%AE%E3%82%AD%E3%83%A3%E3%83%AA%E3%82%A2%E3%81%AE%E6%96%B0%E3%83%BB%E3%83%AB%E3%83%BC%E3%83%AB-%E6%9D%91%E4%B8%8A-%E8%87%A3/dp/4815608032?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%82%AD%E3%83%A3%E3%83%AA%E3%82%A2&qid=1623672636&s=books&sr=1-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzSFlRSklVUVRBM0FWJmVuY3J5cHRlZElkPUEwODkyMzI1MkFRVU01OFkzUURIMiZlbmNyeXB0ZWRBZElkPUEyOFhWQTBKMTA0MEFTJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ%3D%3D&linkCode=li2&tag=htech-22&linkId=bd8cb037e2f507929891f6f1289321ea&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4815608032&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4815608032" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "1%の努力",
    date: "",
    author: "",
    categories: ["キャリア"],
    url: "https://amzn.to/3xmx9dw",
    img: `<a href="https://www.amazon.co.jp/1-%E3%81%AE%E5%8A%AA%E5%8A%9B-%E3%81%B2%E3%82%8D%E3%82%86%E3%81%8D/dp/4478108498?pf_rd_r=RMSWQ2K7HKYZ5TJW2WQ2&pf_rd_p=309564c6-7435-57f3-8824-21bf29054cc7&pf_rd_s=merchandised-search-10&pf_rd_t=BROWSE&pf_rd_i=492332&linkCode=li2&tag=htech-22&linkId=99e2d9f3cb421dd5d0483fc6acc5c616&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4478108498&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4478108498" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "このまま今の会社にいていいのか?と一度でも思ったら読む 転職の思考法",
    date: "",
    author: "",
    categories: ["キャリア"],
    url: "https://amzn.to/3tGudHa",
    img: `<a href="https://www.amazon.co.jp/%E3%81%93%E3%81%AE%E3%81%BE%E3%81%BE%E4%BB%8A%E3%81%AE%E4%BC%9A%E7%A4%BE%E3%81%AB%E3%81%84%E3%81%A6%E3%81%84%E3%81%84%E3%81%AE%E3%81%8B%EF%BC%9F%E3%81%A8%E4%B8%80%E5%BA%A6%E3%81%A7%E3%82%82%E6%80%9D%E3%81%A3%E3%81%9F%E3%82%89%E8%AA%AD%E3%82%80-%E8%BB%A2%E8%81%B7%E3%81%AE%E6%80%9D%E8%80%83%E6%B3%95-%E5%8C%97%E9%87%8E-%E5%94%AF%E6%88%91-ebook/dp/B07DCLSV6H?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%81%93%E3%81%AE%E3%81%BE%E3%81%BE%E4%BB%8A%E3%81%AE%E4%BC%9A%E7%A4%BE%E3%81%AB%E3%81%84%E3%81%A6%E3%81%84%E3%81%84%E3%81%AE%E3%81%8B%3F%E3%81%A8%E4%B8%80%E5%BA%A6%E3%81%A7%E3%82%82%E6%80%9D%E3%81%A3%E3%81%9F%E3%82%89%E8%AA%AD%E3%82%80&qid=1623672684&s=books&sr=1-2&linkCode=li2&tag=htech-22&linkId=e8c302ce291056b71b9d3aa5d489ccfa&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07DCLSV6H&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B07DCLSV6H" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "マンガ　このまま今の会社にいていいのか？と一度でも思ったら読む 転職の思考法",
    date: "2021/7/6",
    author: "北野 唯我、星井 博文、松枝 尚嗣",
    categories: ["キャリア"],
    url: "https://amzn.to/3Eeuerj",
    img: `<a href="https://www.amazon.co.jp/gp/product/B094HZTRMG?ie=UTF8&linkCode=li2&tag=htech-22&linkId=05519158e5084f319325a96bf3bb993b&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B094HZTRMG&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B094HZTRMG" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  // `リモートワーク`
  {
    title: "リモートチームでうまくいく",
    date: "",
    author: "",
    categories: ["リモートワーク"],
    url: "https://amzn.to/3uy0Ar7",
    img: `<a href="https://www.amazon.co.jp/%E3%83%AA%E3%83%A2%E3%83%BC%E3%83%88%E3%83%81%E3%83%BC%E3%83%A0%E3%81%A7%E3%81%86%E3%81%BE%E3%81%8F%E3%81%84%E3%81%8F-%E5%80%89%E8%B2%AB-%E7%BE%A9%E4%BA%BA/dp/4534053428?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%83%AA%E3%83%A2%E3%83%BC%E3%83%88%E3%83%81%E3%83%BC%E3%83%A0%E3%81%A7%E3%81%86%E3%81%BE%E3%81%8F%E3%81%84%E3%81%8F&qid=1623673005&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=2b2db9934f7e15f17e85e19993964959&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4534053428&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4534053428" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "リモートワーク大全",
    date: "",
    author: "",
    categories: ["リモートワーク"],
    url: "https://amzn.to/3gCAwX0",
    img: `<a href="https://www.amazon.co.jp/%E3%83%AA%E3%83%A2%E3%83%BC%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF%E5%A4%A7%E5%85%A8-%E5%A3%BD%E3%81%8B%E3%81%8A%E3%82%8A-ebook/dp/B08NC5KH2L?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%83%AA%E3%83%A2%E3%83%BC%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF&qid=1623672710&s=books&sr=1-3&linkCode=li2&tag=htech-22&linkId=9d43f2422f9ca5379178343e1de0fa2b&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08NC5KH2L&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B08NC5KH2L" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "ひきこもり×在宅×IT=可能性無限大! 株式会社ウチらめっちゃ細かいんで",
    date: "",
    author: "",
    categories: ["リモートワーク"],
    url: "https://amzn.to/3zjvVS1",
    img: `<a href="https://www.amazon.co.jp/%E3%81%B2%E3%81%8D%E3%81%93%E3%82%82%E3%82%8A%C3%97%E5%9C%A8%E5%AE%85%C3%97-%E5%8F%AF%E8%83%BD%E6%80%A7%E7%84%A1%E9%99%90%E5%A4%A7-%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E3%82%A6%E3%83%81%E3%82%89%E3%82%81%E3%81%A3%E3%81%A1%E3%82%83%E7%B4%B0%E3%81%8B%E3%81%84%E3%82%93%E3%81%A7-%E4%BD%90%E8%97%A4%E5%95%93/dp/486667248X?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=34MG5OBNJB8HF&dchild=1&keywords=%E3%83%AA%E3%83%A2%E3%83%BC%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF%E3%81%A7%E7%94%9F%E3%81%8D%E3%81%A6%E3%81%84%E3%81%8F&qid=1623671716&sprefix=%E3%83%AA%E3%83%A2%E3%83%BC%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF%E3%81%A7%2Caps%2C275&sr=8-16&linkCode=li2&tag=htech-22&linkId=5f037cbea6bbbfc62d688d875a50f893&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=486667248X&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=486667248X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  // `ライフハック`
  {
    title: `デジタル・ミニマリスト スマホに依存しない生き方 `,
    date: "",
    author: "",
    categories: [`ライフハック`],
    url: `https://amzn.to/2TwvFOT`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E3%83%BB%E3%83%9F%E3%83%8B%E3%83%9E%E3%83%AA%E3%82%B9%E3%83%88-%E3%82%B9%E3%83%9E%E3%83%9B%E3%81%AB%E4%BE%9D%E5%AD%98%E3%81%97%E3%81%AA%E3%81%84%E7%94%9F%E3%81%8D%E6%96%B9-%E3%83%8F%E3%83%A4%E3%82%AB%E3%83%AF%E6%96%87%E5%BA%AB-NF-573/dp/415050573X?pf_rd_r=RMSWQ2K7HKYZ5TJW2WQ2&pf_rd_p=2c38391f-03ae-5979-bab5-3d6790b519c3&pf_rd_s=merchandised-search-10&pf_rd_t=BROWSE&pf_rd_i=492332&linkCode=li2&tag=htech-22&linkId=8d73546c803af816d0f2895bb244d9c9&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=415050573X&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=415050573X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: "シリコンバレー式超ライフハック",
    date: "",
    author: "",
    categories: ["ライフハック"],
    url: "https://amzn.to/3wpbrW7",
    img: `<a href="https://www.amazon.co.jp/%E3%82%B7%E3%83%AA%E3%82%B3%E3%83%B3%E3%83%90%E3%83%AC%E3%83%BC%E5%BC%8F%E8%B6%85%E3%83%A9%E3%82%A4%E3%83%95%E3%83%8F%E3%83%83%E3%82%AF-%E3%83%87%E3%82%A4%E3%83%B4%E3%83%BB%E3%82%A2%E3%82%B9%E3%83%97%E3%83%AA%E3%83%BC/dp/447810719X?_encoding=UTF8&qid=1623672759&sr=1-3&linkCode=li2&tag=htech-22&linkId=7524b30500f92235e4927e8cc794c2d0&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=447810719X&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=447810719X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  // `ガジェット`
  {
    title: "Apple AirPods Pro",
    date: "",
    author: "",
    categories: ["ガジェット"],
    url: "https://amzn.to/2TtrXpk",
    img: `<a href="https://www.amazon.co.jp/gp/product/B07ZPS4FSW?ie=UTF8&linkCode=li2&tag=htech-22&linkId=bd15cb82d3a02148dae872848b90dc30&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07ZPS4FSW&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B07ZPS4FSW" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: `/items/apple-airpods-pro.jpg`,
    description: "",
  },
  {
    title: "Happy Hacking Keyboard Professional HYBRID Type-S",
    date: "",
    author: "",
    categories: ["ガジェット"],
    url: "https://amzn.to/3d9u2hQ",
    img: `<a href="https://www.amazon.co.jp/HHKB-Professional-HYBRID-%E6%97%A5%E6%9C%AC%E8%AA%9E%E9%85%8D%E5%88%97%EF%BC%8F%E5%A2%A8-PD-KB820BS/dp/B082TSZ27D?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=Happy+Hacking+Keyboard+Professional+HYBRID+Type-S&qid=1631710148&sr=8-3&linkCode=li2&tag=htech-22&linkId=7aa96e966fafabbb99044d937167d726&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B082TSZ27D&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B082TSZ27D" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: `/items/hhkb.jpg`,
    description: "",
  },
  {
    title: "Gtracing ゲーミングチェア オットマン GT901BLACK",
    date: "",
    author: "",
    categories: ["ガジェット"],
    url: "https://amzn.to/2Za9wFP",
    img: `<a href="https://www.amazon.co.jp/GTRACING-%E5%A4%9A%E6%A9%9F%E8%83%BD-%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0%E3%83%81%E3%82%A7%E3%82%A2-GT909-GT901-BLACK/dp/B07JJ848L8?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=Gtracing%2B%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0%E3%83%81%E3%82%A7%E3%82%A2%2B%E3%82%AA%E3%83%83%E3%83%88%E3%83%9E%E3%83%B3%2BGT901BLACK&qid=1631710185&sr=8-6&th=1&linkCode=li2&tag=htech-22&linkId=2ab37fd14e8c827d111376245dca6064&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07JJ848L8&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B07JJ848L8" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: `/items/gt-racing.jpg`,
    description: "",
  },
  {
    title: "Blue Microphones Yeti X フラグシップ USB コンデンサー マイク BM600X Blackout ブラック ゲーミング ストリーミング カスタマイズ可能なLEDライティング Windows MAC テレワーク 国内正規品 2年間メーカー保証",
    date: "",
    author: "",
    categories: ["ガジェット"],
    url: "https://amzn.to/3mDeSoe",
    img: `<a href="https://www.amazon.co.jp/gp/product/B08TQQ2VMZ?ie=UTF8&th=1&linkCode=li2&tag=htech-22&linkId=ea177af1333ea8779d204f9f3ab3c14d&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08TQQ2VMZ&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B08TQQ2VMZ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: `/items/gt-racing.jpg`,
    description: "",
  },
  {
    title: "Anker Eufy RoboVac 11S (ロボット掃除機)【BoostIQ搭載 / 超薄型 / 強力吸引 / 静音設計 / 自動充電 / 落下防止 / 衝突防止】(ホワイト)",
    date: "",
    author: "",
    categories: ["ガジェット", `ライフハック`],
    url: "https://amzn.to/3pchKuU",
    img: `<a href="https://www.amazon.co.jp/RoboVac-%E3%83%AD%E3%83%9C%E3%83%83%E3%83%88%E6%8E%83%E9%99%A4%E6%A9%9F-Anker-%E3%80%90BoostIQ%E6%90%AD%E8%BC%89-1300Pa%E3%81%AE%E5%BC%B7%E5%8A%9B%E5%90%B8%E5%BC%95/dp/B07D7TR3D5?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=36ULXVSJ4L49M&keywords=anker+robovac&qid=1640008991&s=home&sprefix=anker+robo+vac%2Ckitchen%2C311&sr=1-4&linkCode=li3&tag=htech-22&linkId=8bfe1c7556734b869fb78dc59fcafa3e&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07D7TR3D5&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li3&o=9&a=B07D7TR3D5" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: ``,
    description: "",
  },
  {
    title: "Gakkiti eufy RoboVac 14.4V 2600mAh互換用バッテリーパック RoboVac 11 RoboVac 11S RoboVac 30機種適合",
    date: "",
    author: "",
    categories: [],
    url: "https://amzn.to/3FewPBP",
    img: `<a href="https://www.amazon.co.jp/gp/product/B083J6DRDF?ie=UTF8&psc=1&linkCode=li3&tag=htech-22&linkId=5db2f340c8630b460d28d386e7a3dc6f&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B083J6DRDF&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li3&o=9&a=B083J6DRDF" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: ``,
    description: "",
  },
  {
    title: "[ドウシシャ]ルミナス互換 スチールラック ブラック スリムタイプ 幅60×奥行30×高さ150cm パーツ対応 M1560305BK",
    date: "",
    author: "",
    categories: ["ガジェット", `ライフハック`],
    url: "https://amzn.to/3IjMeCq",
    img: `<a href="https://www.amazon.co.jp/%E3%80%90%E5%88%A5%E6%B3%A8%E5%93%81%E3%80%91-%E3%83%A1%E3%82%BF%E3%83%AB%E3%83%A9%E3%83%83%E3%82%AF-%E3%82%B9%E3%83%AA%E3%83%A0%E3%82%BF%E3%82%A4%E3%83%97-%E5%B9%8560%C3%97%E5%A5%A5%E8%A1%8C30%C3%97%E9%AB%98%E3%81%95150cm-M1560305BK/dp/B01M0K955J?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&ac_md=0-0-44Oh44K%2F44Or44Op44OD44Kv-ac_d_rm_rm_rm&crid=2MXQ4YPW75KG0&cv_ct_cx=%E3%83%A1%E3%82%BF%E3%83%AB%E3%83%A9%E3%83%83%E3%82%AF&keywords=%E3%83%A1%E3%82%BF%E3%83%AB%E3%83%A9%E3%83%83%E3%82%AF&pd_rd_i=B01M0K955J&pd_rd_r=b373fd73-cb28-4b42-8531-e664b60ecf21&pd_rd_w=JpSPT&pd_rd_wg=QHRqm&pf_rd_p=899a88e9-0734-4bf7-a3d7-3b1fa1f60317&pf_rd_r=2YRWB4D75GD2K9ET6EK0&psc=1&qid=1645703565&sprefix=%E3%83%A1%E3%82%BF%E3%83%AB%E3%83%A9%E3%83%83%E3%82%AF%2Caps%2C257&sr=1-1-eae12a9f-704c-43fb-a70a-f41f45ba37e4&linkCode=li2&tag=htech-22&linkId=40aa5aaadfe2009bb9e12c48deca5b7b&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01M0K955J&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B01M0K955J" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: ``,
    description: "",
  },
  {
    title: "Amazonベーシック 5段スチールラック キャスター付き 耐荷重200kg 幅76×奥行36×高さ165cm ブラック",
    date: "",
    author: "",
    categories: ["ガジェット", `ライフハック`],
    url: "https://amzn.to/3IglZwD",
    img: `<a href="https://www.amazon.co.jp/Amazon%E3%83%99%E3%83%BC%E3%82%B7%E3%83%83%E3%82%AF-5%E6%AE%B5%E3%83%A6%E3%83%8B%E3%83%83%E3%83%88%E3%82%B7%E3%82%A7%E3%83%AB%E3%83%95-10cm-4%E3%82%A4%E3%83%B3%E3%83%81-%E3%82%AD%E3%83%A3%E3%82%B9%E3%82%BF%E3%83%BC%E4%BB%98%E3%80%81%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF/dp/B071DZHLXH?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=2MXQ4YPW75KG0&keywords=%E3%83%A1%E3%82%BF%E3%83%AB%E3%83%A9%E3%83%83%E3%82%AF&qid=1645703565&sprefix=%E3%83%A1%E3%82%BF%E3%83%AB%E3%83%A9%E3%83%83%E3%82%AF%2Caps%2C257&sr=8-1-spons&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEyNjBONzVBVUFBMjNUJmVuY3J5cHRlZElkPUExMDEzNzQySEE1TDFRS1RIRDZIJmVuY3J5cHRlZEFkSWQ9QTJCN05XWkRURkxIMVkmd2lkZ2V0TmFtZT1zcF9hdGYmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl&th=1&linkCode=li2&tag=htech-22&linkId=4c695925a45e9e9fdefee10ca6cca780&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B071DZHLXH&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B071DZHLXH" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: ``,
    description: "",
  },
  {
    title: "パナソニック 食器洗い洗浄機 スリムサイズ スチールグレー NP-TSK1-H",
    date: "",
    author: "",
    categories: ["ガジェット", `ライフハック`],
    url: "https://amzn.to/3IjHYTt",
    img: `<a href="https://www.amazon.co.jp/%E3%83%91%E3%83%8A%E3%82%BD%E3%83%8B%E3%83%83%E3%82%AF-%E9%A3%9F%E5%99%A8%E6%B4%97%E3%81%84%E6%B4%97%E6%B5%84%E6%A9%9F-%E3%82%B9%E3%83%AA%E3%83%A0%E3%82%B5%E3%82%A4%E3%82%BA-%E3%83%9B%E3%83%AF%E3%82%A4%E3%83%88-NP-TSK1-W/dp/B09G2K4TWG?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=152WW9OM79D6Q&keywords=%E9%A3%9F%E6%B4%97%E6%A9%9F%2B%E3%83%91%E3%83%8A%E3%82%BD%E3%83%8B%E3%83%83%E3%82%AF&qid=1645703780&sprefix=%E9%A3%9F%E6%B4%97%E6%A9%9F%2B%E3%81%B1%E3%81%AA%E3%81%9D%E3%81%AB%E3%81%A3%E3%81%8F%2Caps%2C216&sr=8-5&th=1&linkCode=li2&tag=htech-22&linkId=2841358b773a131e53c04c02cd13bd1b&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09G2K4TWG&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B09G2K4TWG" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: ``,
    description: "",
  },
  {
    title: "パナソニック 食器洗い乾燥機 プチ食洗 ホワイト NP-TCR4-W",
    date: "",
    author: "",
    categories: ["ガジェット", `ライフハック`],
    url: "https://amzn.to/3hdy4H0",
    img: `<a href="https://www.amazon.co.jp/%E3%83%91%E3%83%8A%E3%82%BD%E3%83%8B%E3%83%83%E3%82%AF-%E9%A3%9F%E5%99%A8%E6%B4%97%E3%81%84%E4%B9%BE%E7%87%A5%E6%A9%9F%EF%BC%88%E3%83%9B%E3%83%AF%E3%82%A4%E3%83%88%EF%BC%89%E3%80%90%E9%A3%9F%E6%B4%97%E6%A9%9F%E3%80%91-Panasonic-%E3%83%97%E3%83%81%E9%A3%9F%E6%B4%97-NP-TCR4-W/dp/B01NCY4IAG?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=152WW9OM79D6Q&keywords=%E9%A3%9F%E6%B4%97%E6%A9%9F+%E3%83%91%E3%83%8A%E3%82%BD%E3%83%8B%E3%83%83%E3%82%AF&qid=1645703780&sprefix=%E9%A3%9F%E6%B4%97%E6%A9%9F+%E3%81%B1%E3%81%AA%E3%81%9D%E3%81%AB%E3%81%A3%E3%81%8F%2Caps%2C216&sr=8-6&linkCode=li2&tag=htech-22&linkId=5bc19f6eb5db77077238c41c20793d3a&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01NCY4IAG&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B01NCY4IAG" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: ``,
    description: "",
  },
  {
    title: "パナソニック 食器洗い乾燥機 NP-TZ300-S",
    date: "",
    author: "",
    categories: ["ガジェット", `ライフハック`],
    url: "https://amzn.to/3hfcbHc",
    img: `<a href="https://www.amazon.co.jp/%E3%83%91%E3%83%8A%E3%82%BD%E3%83%8B%E3%83%83%E3%82%AF-Panasonic-%E9%A3%9F%E5%99%A8%E6%B4%97%E3%81%84%E4%B9%BE%E7%87%A5%E6%A9%9F-NP-TZ300-S/dp/B08D66S187?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=152WW9OM79D6Q&keywords=%E9%A3%9F%E6%B4%97%E6%A9%9F+%E3%83%91%E3%83%8A%E3%82%BD%E3%83%8B%E3%83%83%E3%82%AF&qid=1645703780&sprefix=%E9%A3%9F%E6%B4%97%E6%A9%9F+%E3%81%B1%E3%81%AA%E3%81%9D%E3%81%AB%E3%81%A3%E3%81%8F%2Caps%2C216&sr=8-7&linkCode=li2&tag=htech-22&linkId=baf280c7548b425a0c6cce2388470897&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08D66S187&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B08D66S187" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    staticImg: ``,
    description: "",
  },
  /*
  {
    title: "",
    date: "",
    author: "",
    categories: ["ガジェット"],
    url: "",
    img: ``,
    staticImg: ``,
    description: "",
  },
  */
  // なし
  {
    title: "コトラーのマーケティング思考法",
    date: "",
    author: "",
    categories: [`マーケティング`],
    url: "https://amzn.to/3odELM0",
    img: "",
    description: "",
  },
  {
    title: "死ぬ気で働いたあとの世界を君は見たくないか!?",
    date: "",
    author: "",
    categories: [`キャリア`],
    url: "https://amzn.to/3uLBepJ",
    img: `<a href="https://www.amazon.co.jp/%E6%AD%BB%E3%81%AC%E6%B0%97%E3%81%A7%E5%83%8D%E3%81%84%E3%81%9F%E3%81%82%E3%81%A8%E3%81%AE%E4%B8%96%E7%95%8C%E3%82%92%E5%90%9B%E3%81%AF%E8%A6%8B%E3%81%9F%E3%81%8F%E3%81%AA%E3%81%84%E3%81%8B-%E6%97%A9%E5%B7%9D-%E5%8B%9D/dp/4761268980?_encoding=UTF8&qid=1623671848&sr=1-1&linkCode=li2&tag=htech-22&linkId=5f915dbda2ef519e142a95f7c4066428&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4761268980&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4761268980" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: "エンジニアのための時間管理術",
    date: "",
    author: "",
    categories: [`ライフハック`],
    url: "https://amzn.to/3boU0wb",
    img: "",
    description: "",
  },
  {
    title: "Head First Java 第2版 ―頭とからだで覚えるJavaの基本",
    date: "",
    author: "",
    categories: [`プログラミング`],
    url: "https://amzn.to/2JjYfP7",
    img: "",
    description: "",
  },
  {
    title: "地道力[新版] 目先の追求だけでは、成功も幸せも得られない!",
    date: "",
    author: "",
    categories: [],
    url: "https://amzn.to/3tMWnzk",
    img: "",
    description: "",
  },
  {
    title: "鬼速PDCA",
    date: "",
    author: "",
    categories: [],
    url: "https://amzn.to/3bnznkh",
    img: "",
    description: "",
  },
  {
    title: "道は開ける 文庫版",
    date: "",
    author: "",
    categories: [],
    url: "https://amzn.to/3d2bEaY",
    img: "",
    description: "",
  },
  {
    title: "大学4年間のマーケティングが10時間でざっと学べる",
    date: "",
    author: "",
    categories: [`マーケティング`],
    url: "https://amzn.to/3hh75LL",
    img: "",
    description: "",
  },
  {
    title: "学びを結果に変えるアウトプット大全",
    date: "",
    author: "",
    categories: [`ビジネス`],
    url: "https://amzn.to/2TtrXpk",
    img: `<a href="https://www.amazon.co.jp/dp/4801400558?psc=1&pd_rd_i=4801400558p13NParams&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFZOU9VSVVFWjlQTFkmZW5jcnlwdGVkSWQ9QTAwNTcyNTcyUkU4VURWUkFBMlc2JmVuY3J5cHRlZEFkSWQ9QTNRVktTN1Y1NTdSVk4md2lkZ2V0TmFtZT1zcF9kZXRhaWwmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl&linkCode=li2&tag=htech-22&linkId=38c5687e2629daa5d1ea066ed020b13f&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4801400558&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4801400558" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: `ゼブラ デスクペン フロス 0.7 グラスブラック BA65-GBK`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3jVCmUt`,
    img: `<a href="https://www.amazon.co.jp/gp/product/B003G0WUAE?ie=UTF8&psc=1&linkCode=li2&tag=htech-22&linkId=d4ee0abbd907186e4ae4fd34d62fcd99&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B003G0WUAE&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B003G0WUAE" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `ゼブラ 油性ボールペン替芯 H-0.7芯 黒 10本 B-BR-6A-H-BK`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3xR3hpk`,
    img: `<a href="https://www.amazon.co.jp/%E3%82%BC%E3%83%96%E3%83%A9-%E6%B2%B9%E6%80%A7%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%9A%E3%83%B3%E6%9B%BF%E8%8A%AF-H-0-7%E8%8A%AF-10%E6%9C%AC-B-BR-6A-H-BK/dp/B000UI6W6I?pd_rd_w=2IbYI&pf_rd_p=367c54b8-500b-4071-9b4d-65fe16192688&pf_rd_r=NWMRG2HDXPP76XJD174M&pd_rd_r=73c5db6d-08b7-4bc0-bc99-a94d600b58e0&pd_rd_wg=7TmaG&pd_rd_i=B000UI6W6I&psc=1&linkCode=li2&tag=htech-22&linkId=8d03311b21c176017c2daac46ae8b9b8&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B000UI6W6I&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B000UI6W6I" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `ゼブラ 手帳用ボールペン T-3`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3iNxK3l`,
    img: `<a href="https://www.amazon.co.jp/ZEBRA-%E3%82%BC%E3%83%96%E3%83%A9-T-3-%E6%89%8B%E5%B8%B3%E7%94%A8%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%9A%E3%83%B3/dp/B0018RHUM0?pd_rd_i=B0018RHUM0&psc=1&linkCode=li2&tag=htech-22&linkId=4b4b2d232fae2969065ee47a0635392b&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0018RHUM0&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B0018RHUM0" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `ゼブラ 油性ボールペン 4C-0.7 替芯 0.7mm 黒 BR-8A-4C-BK 2個セット`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3jYcF5J`,
    img: `<a href="https://www.amazon.co.jp/%E3%82%BC%E3%83%96%E3%83%A9-%E6%B2%B9%E6%80%A7%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%9A%E3%83%B3-4C-0-7-0-7mm-BR-8A-4C-BK/dp/B07ZD9RDBJ?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E3%82%BC%E3%83%96%E3%83%A9+4C-0.7%E8%8A%AF&qid=1629128522&sr=8-5&linkCode=li2&tag=htech-22&linkId=a2ddd99b7e4c7050fbfadc848003b207&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07ZD9RDBJ&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B07ZD9RDBJ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `ゼブラ シャープペン 手帳用 0.5mm TS-3`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3jX8LK7`,
    img: `<a href="https://www.amazon.co.jp/%E3%82%BC%E3%83%96%E3%83%A9-%E3%82%B7%E3%83%A3%E3%83%BC%E3%83%97%E3%83%9A%E3%83%B3-%E6%89%8B%E5%B8%B3%E7%94%A8-0-5mm-TS-3/dp/B0018RF0IQ?pd_rd_w=rBnjJ&pf_rd_p=d8d2cdb7-8ddc-4a8c-97b1-5847203fd4bd&pf_rd_r=83KFP5ZQFGAAG4ZQM27Q&pd_rd_r=278b4852-5093-415b-bd0e-5a0fc83c3cb3&pd_rd_wg=HY35P&pd_rd_i=B0018RF0IQ&psc=1&linkCode=li2&tag=htech-22&linkId=406330a0b888de2b7ca68791f30c85b8&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0018RF0IQ&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B0018RF0IQ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `パイロット バーディ ステンレススチールボディ ミニボールペン 0.7mm ブラックインク (BS-40S-S)`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/37JVQ8X`,
    img: `<a href="https://www.amazon.co.jp/Pilot-BS-40S-%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%9A%E3%83%B3%E3%83%90%E3%83%BC%E3%83%87%E3%82%A3-%E3%80%90%E9%BB%92%E3%80%91%E3%82%B7%E3%83%AB%E3%83%90%E3%83%BC%E3%83%9C%E3%83%87%E3%82%A3-BS40SS/dp/B006CQQPYQ?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=PILOT+%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%9A%E3%83%B3&qid=1629118146&s=office-products&sr=1-264&linkCode=li2&tag=htech-22&linkId=fc73960cea23fb0e0e78674a0aba0d62&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B006CQQPYQ&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B006CQQPYQ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `パイロット 油性ボールペンレフィル 細字0.7mm 黒 BTRF-6F-B 10本`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3yRbCul`,
    img: `<a href="https://www.amazon.co.jp/PILOT-%E3%83%91%E3%82%A4%E3%83%AD%E3%83%83%E3%83%88-%E6%B2%B9%E6%80%A7%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%9A%E3%83%B3%E3%83%AC%E3%83%95%E3%82%A3%E3%83%AB-%E7%B4%B0%E5%AD%970-7mm-%E9%BB%92-BTRF-6F-B-10%E6%9C%AC/dp/B008C0I12Y?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=BTRF-6F-B&qid=1629127988&sr=8-3&linkCode=li2&tag=htech-22&linkId=048362993971c0697b4a9e0baca8318a&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B008C0I12Y&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B008C0I12Y" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `パイロット ミニ シャープペンシル バーディ 0.5mm シルバー (HS-40S)`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3sjeM7M`,
    img: `<a href="https://www.amazon.co.jp/Pilot-HS-40S-%E3%82%B7%E3%83%A3%E3%83%BC%E3%83%97-%E3%83%90%E3%83%BC%E3%83%87%E3%82%A3-HS40SS/dp/B007C0P8DA?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=PILOT+%E3%82%B7%E3%83%A3%E3%83%BC%E3%83%97%E3%83%9A%E3%83%B3&qid=1629118317&s=office-products&sr=1-73&linkCode=li2&tag=htech-22&linkId=9a7ca4a364cb2970c7f0d3c00d70e3ca&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B007C0P8DA&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B007C0P8DA" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `パイロット 多機能ボールペン バーディスイッチ HSBN-50S シルバー`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3iQpuQc`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%91%E3%82%A4%E3%83%AD%E3%83%83%E3%83%88-%E5%A4%9A%E6%A9%9F%E8%83%BD%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%9A%E3%83%B3-%E3%83%90%E3%83%BC%E3%83%87%E3%82%A3%E3%82%B9%E3%82%A4%E3%83%83%E3%83%81-HSBN-50S-%E3%82%B7%E3%83%AB%E3%83%90%E3%83%BC/dp/B001GR2CY8?pd_rd_w=A7eLO&pf_rd_p=3bbfe82e-4f88-4843-ab58-a516dd1b4bd0&pf_rd_r=JK6EPVKBTSPX1ZX7H69H&pd_rd_r=09e220f2-6831-4c98-a458-2110e28ca035&pd_rd_wg=vufCB&pd_rd_i=B001GR2CY8&psc=1&linkCode=li2&tag=htech-22&linkId=26c558b52814fb083c79112291ed3240&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B001GR2CY8&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B001GR2CY8" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `パイロット 油性ボールペンレフィル スイッチ用ボールペンカエシン BTRF-8F-B 10本`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3jYpjBz`,
    img: `<a href="https://www.amazon.co.jp/PILOT-%E3%83%91%E3%82%A4%E3%83%AD%E3%83%83%E3%83%88-%E6%B2%B9%E6%80%A7%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%9A%E3%83%B3%E3%83%AC%E3%83%95%E3%82%A3%E3%83%AB-%E3%82%B9%E3%82%A4%E3%83%83%E3%83%81%E7%94%A8%E3%83%9C%E3%83%BC%E3%83%AB%E3%83%9A%E3%83%B3%E3%82%AB%E3%82%A8%E3%82%B7%E3%83%B3-BTRF-8F-B-10%E6%9C%AC/dp/B008C0I5JI?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=BTRF-8F-B&qid=1629128205&sr=8-2&linkCode=li2&tag=htech-22&linkId=4ef6b1fdf99a5c1d6cd2209813f0d50b&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B008C0I5JI&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B008C0I5JI" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `パーフェクト Ruby on Rails 【増補改訂版】 (Perfect series)`,
    date: "2020/07/25",
    author: "すがわらまさのり、前島真一、橋立友宏、五十嵐邦明、後藤優一",
    categories: ["駆け出しエンジニア"],
    url: `https://amzn.to/37OqVYT`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%91%E3%83%BC%E3%83%95%E3%82%A7%E3%82%AF%E3%83%88-Ruby-Rails-%E3%80%90%E5%A2%97%E8%A3%9C%E6%94%B9%E8%A8%82%E7%89%88%E3%80%91-Perfect/dp/4297114623?adgrpid=106347528898&dchild=1&gclid=Cj0KCQjwvO2IBhCzARIsALw3ASpk0bdu_94181eucizgc0pEZkRAh7W7rYvgMb2R4a6Wgh9ukJKq6LwaApYJEALw_wcB&hvadid=450581240090&hvdev=c&hvlocphy=1009520&hvnetw=g&hvqmt=b&hvrand=6174088431245074536&hvtargid=kwd-387895329967&hydadcr=27294_11561545&jp-ad-ap=0&keywords=%E3%83%91%E3%83%BC%E3%83%95%E3%82%A7%E3%82%AF%E3%83%88+ruby+on+rails&qid=1629187984&sr=8-2-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzNDgzV0o1STVIRktCJmVuY3J5cHRlZElkPUEwMDM3NDMwWFo5UkJZTDhBS0Y3JmVuY3J5cHRlZEFkSWQ9QTMxN0xGN0lOWjBFUTUmd2lkZ2V0TmFtZT1zcF9hdGYmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl&linkCode=li2&tag=htech-22&linkId=5803c93d541630dc9ac7e01f3d486308&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4297114623&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4297114623" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `Linuxコマンドブック ビギナーズ 第5版`,
    date: "",
    author: "",
    categories: [`プログラミング`],
    url: `https://amzn.to/3slJEVi`,
    img: `<a href="https://www.amazon.co.jp/Linux%E3%82%B3%E3%83%9E%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF-%E3%83%93%E3%82%AE%E3%83%8A%E3%83%BC%E3%82%BA-%E7%AC%AC5%E7%89%88-%E5%B7%9D%E5%8F%A3%E6%8B%93%E4%B9%8B/dp/4815602824?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=JERBQBG5V3PT&dchild=1&keywords=linux+%E3%82%B3%E3%83%9E%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF&qid=1629201838&s=books&sprefix=linux+%E3%82%B3%E3%83%9E%E3%83%B3%E3%83%89%2Cstripbooks%2C269&sr=1-1&linkCode=li2&tag=htech-22&linkId=76321b505367da1264cfef7092a9d867&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4815602824&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4815602824" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `1冊ですべて身につくHTML & CSSとWebデザイン入門講座`,
    date: "",
    author: "",
    categories: [`プログラミング`],
    url: `https://amzn.to/3xTl930`,
    img: `<a href="https://www.amazon.co.jp/1%E5%86%8A%E3%81%A7%E3%81%99%E3%81%B9%E3%81%A6%E8%BA%AB%E3%81%AB%E3%81%A4%E3%81%8FHTML-CSS%E3%81%A8Web%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E5%85%A5%E9%96%80%E8%AC%9B%E5%BA%A7-Mana/dp/4797398892?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=38NRI7GYNNGDX&dchild=1&keywords=1%E5%86%8A%E3%81%A7%E3%81%99%E3%81%B9%E3%81%A6%E8%BA%AB%E3%81%AB%E3%81%A4%E3%81%8Fhtml+%26+css%E3%81%A8web%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E5%85%A5%E9%96%80%E8%AC%9B%E5%BA%A7&qid=1629203273&sprefix=1%E5%86%8A%E3%81%A7%2Caps%2C195&sr=8-1&linkCode=li2&tag=htech-22&linkId=8aa726f657270ac8e40e1e246e090dcf&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4797398892&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4797398892" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `初めてのWebGL 2 第2版 ―JavaScriptで開発するリアルタイム3Dアプリケーション`,
    date: "2021/7/21",
    author: "Farhad Ghayour、 Diego Cantor、 あんどうやすし",
    categories: [`プログラミング`],
    url: `https://amzn.to/3iWqfao`,
    img: `<a href="https://www.amazon.co.jp/%E5%88%9D%E3%82%81%E3%81%A6%E3%81%AEWebGL-%E7%AC%AC2%E7%89%88-%E2%80%95JavaScript%E3%81%A7%E9%96%8B%E7%99%BA%E3%81%99%E3%82%8B%E3%83%AA%E3%82%A2%E3%83%AB%E3%82%BF%E3%82%A4%E3%83%A03D%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3-Farhad-Ghayour/dp/4873119375?pd_rd_w=eboa1&pf_rd_p=c508103a-a68f-41c3-9a1f-e3282ec4f364&pf_rd_r=273NZDM3KJAXDMQ7TPV0&pd_rd_r=02f942c6-ebce-4f71-b2af-3ea870519b68&pd_rd_wg=3esPI&pd_rd_i=4873119375&linkCode=li2&tag=htech-22&linkId=6d7a8f3fd4e856d55c73b1403fdd665f&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4873119375&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4873119375" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `独習Ruby 新版`,
    date: "2021/9/13",
    author: "山田 祥寛",
    categories: [`プログラミング`],
    url: `https://amzn.to/3gyk22X`,
    img: `<a href="https://www.amazon.co.jp/%E7%8B%AC%E7%BF%92Ruby-%E6%96%B0%E7%89%88-%E5%B1%B1%E7%94%B0-%E7%A5%A5%E5%AF%9B/dp/479816884X?dchild=1&qid=1629344900&refinements=p_n_publication_date%3A82839051&rnid=82836051&s=books&sr=1-3&linkCode=li2&tag=htech-22&linkId=8336b00e231ab0cc343a4c364bc2b9b8&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=479816884X&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=479816884X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `並行プログラミング入門 ―Rust、C、アセンブリによる実装からのアプローチ`,
    date: "2021/8/24",
    author: "高野 祐輝",
    categories: [`プログラミング`],
    url: `https://amzn.to/2WbcQ5w`,
    img: `<a href="https://www.amazon.co.jp/%E4%B8%A6%E8%A1%8C%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%E5%85%A5%E9%96%80-%E2%80%95Rust%E3%80%81C%E3%80%81%E3%82%A2%E3%82%BB%E3%83%B3%E3%83%96%E3%83%AA%E3%81%AB%E3%82%88%E3%82%8B%E5%AE%9F%E8%A3%85%E3%81%8B%E3%82%89%E3%81%AE%E3%82%A2%E3%83%97%E3%83%AD%E3%83%BC%E3%83%81-%E9%AB%98%E9%87%8E-%E7%A5%90%E8%BC%9D/dp/4873119596?dchild=1&qid=1629344985&refinements=p_n_publication_date%3A82839051&rnid=82836051&s=books&sr=1-1&linkCode=li2&tag=htech-22&linkId=4f551c3fc6d42b0c2ad1d66160cdeec0&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4873119596&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=4873119596" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `モダンJavaSciptの基本から始める React実践の教科書(最新ReactHooks対応)`,
    date: "2021/9/17",
    author: "岡田 拓巳",
    categories: [`プログラミング`],
    url: `https://amzn.to/3k5ENnx`,
    img: `<a href="https://www.amazon.co.jp/%E3%83%A2%E3%83%80%E3%83%B3JavaScipt%E3%81%AE%E5%9F%BA%E6%9C%AC%E3%81%8B%E3%82%89%E5%A7%8B%E3%82%81%E3%82%8B-React%E5%AE%9F%E8%B7%B5%E3%81%AE%E6%95%99%E7%A7%91%E6%9B%B8-%E6%9C%80%E6%96%B0ReactHooks%E5%AF%BE%E5%BF%9C-%E5%B2%A1%E7%94%B0-%E6%8B%93%E5%B7%B3/dp/481561072X?dchild=1&qid=1629344985&refinements=p_n_publication_date%3A82839051&rnid=82836051&s=books&sr=1-6&linkCode=li2&tag=htech-22&linkId=0db1b40f171b0bfec27cf9351c991c9c&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=481561072X&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=481561072X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `Magentaで開発 AI作曲`,
    date: "2021/7/16",
    author: "斎藤喜寛",
    categories: [`プログラミング`],
    url: `https://amzn.to/3kQYTDB`,
    img: `<a href="https://www.amazon.co.jp/gp/product/B09BB5M423?ie=UTF8&linkCode=li2&tag=htech-22&linkId=4d6596dc64f8c05ba3fb7a852296b2c0&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09BB5M423&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B09BB5M423" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `DIME(ダイム) 2021年 11 月号【特別付録:モバイルPCスタンドMAX】 [雑誌]`,
    date: "2021/9/16",
    author: "小学館",
    categories: [],
    url: `https://amzn.to/3nKPwHl`,
    img: `<a href="https://www.amazon.co.jp/DIME-%E3%83%80%E3%82%A4%E3%83%A0-2021%E5%B9%B4-%E6%9C%88%E5%8F%B7%E3%80%90%E7%89%B9%E5%88%A5%E4%BB%98%E9%8C%B2-%E3%83%A2%E3%83%90%E3%82%A4%E3%83%ABPC%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%89MAX%E3%80%91/dp/B09CRXYJP2?&linkCode=li2&tag=htech-22&linkId=39843459ad7351a6c30daa4488fad761&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09CRXYJP2&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B09CRXYJP2" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: "Leap Motion 小型モーションコントローラー 3Dモーション キャプチャー システム",
    date: "",
    author: "",
    categories: [""],
    url: "https://amzn.to/3Gv1c8f",
    img: `<a href="https://www.amazon.co.jp/gp/product/B00GWCATS8?ie=UTF8&linkCode=li2&tag=htech-22&linkId=8f4621e6b63d0701510623ba4bcf6db7&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00GWCATS8&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B00GWCATS8" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: "",
  },
  {
    title: `Amazonベーシック USB3.0ケーブル 1.8m (タイプAオス - マイクロタイプBオス)`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3EaM4L9`,
    img: `<a href="https://www.amazon.co.jp/gp/product/B00NH12R1O?ie=UTF8&psc=1&linkCode=li2&tag=htech-22&linkId=0d44f16fa403aa91c7f5e938c800e6ca&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NH12R1O&Format=_SL160_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li2&o=9&a=B00NH12R1O" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
  {
    title: `サンスター文具 メタルペンシル metacil メタシル ブラック S4541120`,
    date: "",
    author: "",
    categories: [],
    url: `https://amzn.to/3SLUZub`,
    img: `<a href="https://www.amazon.co.jp/%E3%82%B5%E3%83%B3%E3%82%B9%E3%82%BF%E3%83%BC%E6%96%87%E5%85%B7-%E3%83%A1%E3%82%BF%E3%83%AB%E3%83%9A%E3%83%B3%E3%82%B7%E3%83%AB-metacil-%E3%83%A1%E3%82%BF%E3%82%B7%E3%83%AB-S4541162/dp/B09X9GK157?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&keywords=metacil%EF%BC%88%E3%83%A1%E3%82%BF%E3%82%B7%E3%83%AB%EF%BC%89&qid=1665235624&qu=eyJxc2MiOiIzLjA3IiwicXNhIjoiMS41NSIsInFzcCI6IjAuMDAifQ%3D%3D&sr=8-1&th=1&linkCode=li3&tag=htech-22&linkId=fd4bcd7a2c8c6f329acc163b0cfb83ee&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09X9GK157&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=htech-22&language=ja_JP" ></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=htech-22&language=ja_JP&l=li3&o=9&a=B09X9GK157" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />`,
    description: ``,
  },
]

/*
  {
    title: ``,
    date: "",
    author: "",
    categories: [`駆け出しエンジニア`, `SES`, `受託`, `ベンチャー`, `ビジネス`, `プログラミング`, `マーケティング`, `セキュリティ`, `キャリア`, `リモートワーク`, `ライフハック`, `ガジェット`],
    url: ``,
    img: ``,
    description: ``,
  },
  {
    title: ``,
    date: "",
    author: "",
    categories: [],
    url: ``,
    img: ``,
    description: ``,
  },
  {
    title: ``,
    date: "",
    author: "",
    categories: [`プログラミング`],
    url: ``,
    img: ``,
    description: ``,
  },
*/
