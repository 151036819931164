import React from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import { FluidImg } from "./FluidImg";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0;
`

const MarqueeWrapper = styled.div`
  width: 100%;
  margin: .15rem 0;
  img {
    display: block;
  }
`

const ImageWrapper = styled.div`
  width: 100px;
  max-height: 100%;
  margin-right: 15vw;
  //padding: 2rem 2rem 2rem 4rem;
  box-sizing: border-box;
  .gatsby-image-wrapper {
    //box-shadow: 0 5px 15px #999;
  }
`

export const MarqueeHomepage = ({ speed=20 }) => {
  return (
    <Wrapper>
      <MarqueeWrapper>
        <Marquee speed={speed} gradient={false} pauseOnHover={false}>
          <ImageWrapper>
            <FluidImg fileName="homepage-sample-1.png" />
          </ImageWrapper>
          <ImageWrapper>
            <FluidImg fileName="homepage-sample-2.png" />
          </ImageWrapper>
          <ImageWrapper>
            <FluidImg fileName="homepage-sample-3.png" />
          </ImageWrapper>
          <ImageWrapper>
            <FluidImg fileName="homepage-sample-4.png" />
          </ImageWrapper>
          <ImageWrapper>
            <FluidImg fileName="homepage-sample-5.png" />
          </ImageWrapper>
          <ImageWrapper>
            <FluidImg fileName="homepage-sample-6.png" />
          </ImageWrapper>
          <ImageWrapper>
            <FluidImg fileName="homepage-sample-7.png" />
          </ImageWrapper>
        </Marquee>
      </MarqueeWrapper>
    </Wrapper>
  )
}
