import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#1b1f22"
    },
    secondary: {
      main: "#5d5d5d"
    },
    text: {
      primary: "#101012",
      secondary: "#545456"
    },
    error: {
      main: "#d32f2f"
    },
    success: {
      main: "#28a745"
    },
    white: "#fafafa",
    blue: "#00ccff",
    lightGray: "#f1f1f1",
    border: "#ccc",
    type: "light",
    rainbow: `
      background: -webkit-linear-gradient(45deg, #54d0ff, #9f92ff 20%, #ff7689 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `,
  },
});

export const breakpoints = {
  xs: 600,
  sm: 768,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const categoryColors = {
    "駆け出しエンジニア": "#ff5757",
    "SES": "#8c52ff",
    "受託": "#03989e",
    "ベンチャー": "#38b6ff",
    "ビジネス": "#004aad",
    "プログラミング": "#545454",
    "デザイン": "#87d8f1",
    "VR": "#6329d6",
    "マーケティング": "#ff914d",
    "セキュリティ": "#cb6ce6",
    "キャリア": "#008037",
    "リモートワーク": "#100f0d",
    "ライフハック": "#ffbd59",
    "ガジェット": "#5271ff",
};

export const categoryEn = {
    "駆け出しエンジニア": "TRAINING",
    "SES": "SES",
    "受託": "CONTRACT",
    "ベンチャー": "VENTURE",
    "ビジネス": "BUSINESS",
    "プログラミング": "PROGRAM",
    "デザイン": "DESIGN",
    "VR": "VR",
    "マーケティング": "MARKETING",
    "セキュリティ": "SECURITY",
    "キャリア": "CAREER",
    "リモートワーク": "REMOTE",
    "ライフハック": "LIFE HACK",
    "ガジェット": "GADGET",
};
