import React from "react"
import styled from "styled-components"
import moment from "moment";
import theme, { breakpoints } from "../style/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2%;
  width: 100%;
  margin: 0.25rem 0;
`

const TimeWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2%;
`

const Title = styled.span`
  font-size: 0.6rem;
  line-height: 0.6rem;
  font-family: 'Montserrat', serif;
  font-weight: normal;
  letter-spacing: .13em;
  color: ${theme.palette.text.secondary};
  margin: 0 0.1rem 0 0;
  text-transform: uppercase;
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 0.5rem;
    line-height: 0.5rem;
  }
`

const Time = styled.time`
  font-size: 1.0rem;
  line-height: 1.0rem;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  letter-spacing: .13em;
  color: ${theme.palette.text.secondary};
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
`

export const Dates = ({createdDate, updatedDate}) => {
  return (
    <Wrapper>
      {
      //<Img src="/images/icons/created-date.svg" />
      }
      <TimeWrapper>
        <Title>created</Title>
        <Time
          dateTime={moment(createdDate).format("YYYY.MM.DD")}
          itemProp="datePublished"
        >
          {moment(createdDate).format("YYYY.MM.DD")}
        </Time>
      </TimeWrapper>
      <TimeWrapper>
        <Title>updated</Title>
        {
        //<Img src="/images/icons/updated-date.svg" />
        }
        <Time
          dateTime={moment(updatedDate).format("YYYY.MM.DD")}
          itemProp={"dateModified"}
        >
          {moment(updatedDate).format("YYYY.MM.DD")}
        </Time>
      </TimeWrapper>
    </Wrapper>
  )
}