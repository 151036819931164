import React from "react";
import styled from "styled-components";
import Link from './Link';
import theme from "../style/theme";
import { AmazonLinks } from "../utils/AmazonLinks";

const Wrapper = styled.div`
  width: 100%;
`

//const Button = styled(Link)`
//  position: relative;
//  display: block;
//  padding: 1rem 1rem;
//  margin: 1rem 0;
//  width: 100%;
//  color: ${theme.palette.primary.main};
//  background-color: ${theme.palette.white};
//  border: 1px solid ${theme.palette.primary.main};
//  transition: .3s;
//  text-decoration: none;
//  &::before {
//    content: '詳しくはこちら';
//    position: relative;
//    display: block;
//    width: fit-content;
//    padding: 0.25rem 1rem;
//    font-size: 12px;
//    text-align: center;
//    text-decoration: none;
//    color: ${theme.palette.white};
//    background-color: ${theme.palette.primary.main};
//    transition: .3s;
//  }
//  &::after {
//    content: '';
//    position: absolute;
//    bottom: 10px;
//    right: 10px;
//    width: 0;
//    height: 0;
//    border-style: solid;
//    border-width: 0 0 12px 12px;
//    border-color: transparent transparent ${theme.palette.primary.main} transparent;
//  }
//  &:hover {
//    color: ${theme.palette.white};
//    background-color: ${theme.palette.primary.main};
//    &::before {
//      color: ${theme.palette.primary.main};
//      background-color: ${theme.palette.blue};
//    }
//    &::after {
//      border-color: transparent transparent ${theme.palette.blue} transparent;
//    }
//  }
//`
//
//const Title = styled.p`
//  width: 100%;
//  margin: 0.5rem 0;
//`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
`

//const Img = styled.img`
//  display: block;
//  width: auto;
//  height: auto;
//  max-width: 100%;
//  max-height: 240px;
//  margin: 1rem auto;
//  padding: 0 0;
//  box-sizing: border-box;
//`

export const AdBookLink = ({ title }) => {
  const book = AmazonLinks.find(b => b.title === title)
  return(
    <>
      <Wrapper>
        {
          book.img &&
          <ImgWrapper dangerouslySetInnerHTML={{ __html: book.img }} />
        }
        {
        //<Button
        //  href={book.url}
        //  rel="noopener noreferrer"
        //  target="_blank"
        //>
        //  {
        //    book.staticImg &&
        //    <p>
        //      <Img
        //        alt={title}
        //        src={book.staticImg}
        //      />
        //    </p>
        //  }
        //  <Title>{book.title}</Title>
        //</Button>
        }
      </Wrapper>
      <p>
        <Link href={book.url} isDecoration={`underline`}>{title}</Link>
      </p>
    </>
  )
}
