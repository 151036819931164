import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./src/style/theme";
import "./src/style/layout.scss";
require("prismjs/themes/prism-tomorrow.css");

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);

export const onRouteUpdate = ({ location }) => {

  // Google AdSenseをサイドバーに配置した時に必要
  var sidebar = document.getElementById('sidebar');
  if (sidebar) {
    const observer = new MutationObserver(function (mutations, observer) {
      sidebar.style.height = "";
    });
    observer.observe(sidebar, {
      attributes: true,
      attributeFilter: ['style']
    });
  }
}
