import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  Divider,
} from "@material-ui/core";
import { ArticleImage } from "../components/ArticleImage";
import { Dates } from "../components/Dates";
import theme, { breakpoints, color, categoryColors } from "../style/theme";

const CardWrapper = styled(Link)`
  display: inline-block;
  width: 100%;
  margin: 1rem 0;
  background: transparent;
  background-color: transparent;
  border: none;
  text-decoration: none!important;
  &:hover {
    background-color: transparent!important;
    border: none;
    box-shadow: none;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin: 1rem 0;
  }
`

const Card = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.2rem;
  background: transparent;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  text-decoration: none!important;
  text-transform: none;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, .1);
  picture > img {
    transition: 1s!important;
  }
  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, .3);
    picture > img {
      transform: scale(1.2);
    }
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`

const ArticleImageWrapper = styled.div`
  width: 400px;
  max-width: 400px;
  margin: 0;
  padding: 1rem 0.5rem;
  @media screen and (max-width: ${breakpoints.sm}px) {
    width: 100%;
    max-width: none;
    padding: 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 400px);
  margin: 0;
  padding: 0 1rem;
  box-sizing: border-box;
  @media screen and (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const Tag = styled.span`
  margin: 0.15rem 0.15rem;
  padding: 0.15rem 0.5rem;
  box-sizing: border-box;
  color: ${theme.palette.text.secondary};
  border: 1px solid ${theme.palette.text.secondary};
  border-radius: 1rem;
  font-size: 0.75rem;
  letter-spacing: .13em;
`

const BorderWrapper = styled.div`
  width: 100%;
  margin: 0.5rem 0;
`

const Title = styled.h2`
  margin: 0.5rem 0;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: .13em;
  color: ${theme.palette.text.primary};
`

const Description = styled.p`
  color: ${theme.palette.text.secondary};
  font-size: 0.875rem;
  letter-spacing: .13em;
`

export default ({id, featuredImage, title, category, description, url, createdDate, updatedDate, excerpt, tags}) => {
  return (
    <CardWrapper to={url} key={id}>
      <Card>
        <ArticleImageWrapper>
          <ArticleImage
            category={category}
            color={categoryColors[category]}
            featuredImage={featuredImage}
          />
        </ArticleImageWrapper>
        <ContentWrapper>
          <Title>{title}</Title>
          {
            tags && (
              <TagWrapper>
              {
                tags.map((tag) => {
                  return(<Tag>#{tag}</Tag>)
                })
              }
              </TagWrapper>
            )
          }
          {
            createdDate && updatedDate && 
            <Dates createdDate={createdDate} updatedDate={updatedDate} />
          }
          <BorderWrapper>
            <Divider light />
          </BorderWrapper>
          <Description>{description || excerpt}</Description>
        </ContentWrapper>
      </Card>
    </CardWrapper>
  )
}