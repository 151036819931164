import React from "react";
import { Link } from "gatsby";

const isExternalUrl = url => url && url.startsWith("http");

export default ({ to=null, href=null, children, isDecoration="none", styles={textDecoration: isDecoration}, id=null }) => {
  // Open external links in a new browser tab and internal links using Gatsby's router.
  if (to) {
    return isExternalUrl(to) ? (
      <Link id={id} href={to} rel="noopener noreferrer" target="_blank" style={styles}>{children}</Link>
      //<a href={to} rel="noopener noreferrer" target="_blank">
      //  {children}
      //</a>
    ) : (
      <Link id={id} to={to}>{children}</Link>
    );
  } else {
    return isExternalUrl(href) ? (
      <Link id={id} href={href} rel="noopener noreferrer" target="_blank" style={styles}>{children}</Link>
      //<a href={href} rel="noopener noreferrer" target="_blank">
      //  {children}
      //</a>
    ) : (
      <Link id={id} href={href}>{children}</Link>
    );
  }

};
