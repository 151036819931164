import React from "react";
import styled from "styled-components";
import theme from "../style/theme";
import { Link } from "gatsby";

const Button = styled(Link)`
  position: relative;
  display: block;
  padding: 1rem 1rem;
  margin: 1rem 0;
  width: 100%;
  color: ${theme.palette.primary.main};
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.primary.main};
  transition: .3s;
  text-decoration: none;
  &::before {
    content: '詳しくはこちら';
    position: relative;
    display: block;
    width: fit-content;
    padding: 0.25rem 1rem;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    color: ${theme.palette.white};
    background-color: ${theme.palette.primary.main};
    transition: .3s;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 12px 12px;
    border-color: transparent transparent ${theme.palette.primary.main} transparent;
  }
  &:hover {
    color: ${theme.palette.white};
    background-color: ${theme.palette.primary.main};
    &::before {
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.white};
    }
    &::after {
      border-color: transparent transparent ${theme.palette.white} transparent;
    }
  }
`

const Img = styled.img`
  display: block;
  width: auto;
  height: auto;
  max-width: auto;
  max-height: 240px;
  margin: 1rem auto;
  padding: 0 0;
  box-sizing: border-box;
`

export const AdLink = ({ href, children, alt=null, src=null }) => {
  return(
    <Button
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {
        alt && src &&
        <p>
          <Img
            alt={alt}
            src={src}
          />
        </p>
      }
      {children}
    </Button>
  )
}
